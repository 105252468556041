import React, { useEffect } from 'react';
import { useState } from 'react';
import {connect} from 'react-redux';
import { SolInput } from '../../../../../components';
import { actions } from '../../../actions';
import { actions as commonActions } from '../../../../commonReduxActions/actions';
import moment from "moment/moment";
import {toast} from "react-toastify";
import {Button, Spinner, Form} from "react-bootstrap";
import Stack from "@mui/material/Stack";
import {Controller, useForm} from "react-hook-form";
import Select from "react-select";
import {requestCycle} from "../../../../borrowers/utils";
import {showNotifications} from "../../../../../utils/notification";
import {useMediaQuery} from "react-responsive";
import InfoIcon from "@mui/icons-material/Info";
import {styled} from "@mui/material/styles";
import Tooltip, {tooltipClasses} from "@mui/material/Tooltip";


const UpdateInstallment = ({ contractDetails, closeInstallmentUpdateProcess, ...props }) => {
    const {register, errors, control, clearErrors, handleSubmit, setValue, setError} = useForm()
    const [language, setLanguage] = useState("EN");
    const [installments, setInstallments] = useState([]);
    const [financiers, setFinanciers] = useState([]);
    const [disableUpdateButton, setDisableUpdateButton] = useState(false);
    const [installmentSerialForSuccessMessage, setInstallmentSerialForSuccessMessage] = useState('');
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

    const infoRequiredText = "Above information is required!"
    const invalidFileType = 'Invalid file type! Accepted file types are *.doc, *.docx, *.pdf, *.xls, *.xlsx, *.jpeg, *.jpg, *.webp, *.png and *.csv'
    const invalidSize = 'File size shouldn"t exceed 10MB!'

    // Tooltip for "Document"
    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9',
        },
    }));
    const [showTooltipForDocumentField, setShowTooltipForDocumentField] = useState(false);
    const toolTipTextForDocumentField = <>
        <strong>Accepted file types:</strong> *.doc, *.docx, *.pdf, *.xls, *.xlsx, *.jpeg, '*.jpg, *.webp, *.png, *.csv<br/>
        <strong>Max accepted size:</strong> 10MB
    </>
    const toolTipForDocumentField = () => {
        return <>
            <HtmlTooltip
                title={toolTipTextForDocumentField} placement="right" arrow componentsProps={{
                tooltip: {
                    sx: {
                        '& .MuiTooltip-arrow': {
                            color: 'common.white'
                        }
                    }
                }}} open={showTooltipForDocumentField}
                onOpen={() => setShowTooltipForDocumentField(true)}
                onClose={() => setShowTooltipForDocumentField(false)}>
                <InfoIcon size={'sm'} style={{color: '#F18D00'}} onClick={() => {
                    if (isTabletOrMobile) {
                        setShowTooltipForDocumentField(!showTooltipForDocumentField)
                    }
                }}/>
            </HtmlTooltip>
        </>
    }

    const {
        GET_ORGANISATIONS,
        UPDATE_INSTALLMENT,
        COLLECT_LOAN_DETAILS,
        RETURN_TO_INITIAL_STATES_FOR_COMMON_REDUCER,
        CLEAR_STATES_RELATED_TO_UPDATE_INSTALLMENT_COMPONENT,
        RETURN_TO_INITIAL_STATE
    } = props;

    useEffect(() => {
        setLanguage(props.language)
        let loanSchedules = contractDetails.loan_schedules
        let eligibleInstallmentsForAmountUpdate = []
        for (let i=0; i<loanSchedules.length; i++) {
            if (!loanSchedules[i].invoice_id && loanSchedules[i].status !== "cancelled") {
                // Ideally, there will always be a due invoice
                // Hence, previous installment due date returned from BE, is actually the start date of the current installment (if not rescheduled)
                // But in case of "no invoice is generated yet", we will consider the contract start date (plus grace period) as the (1st) installment start date
                let installmentStartDate = ''
                if (loanSchedules[i - 1]) {
                    installmentStartDate = loanSchedules[i - 1].rescheduled? moment(loanSchedules[i - 1].previous_due_date, "YYYY-MM-DD"):moment(loanSchedules[i - 1].due_date, "YYYY-MM-DD")
                } else {
                    installmentStartDate = moment(contractDetails.effective_from, "YYYY-MM-DD").add(contractDetails.grace_period, 'days')
                }
                let installmentEndDate = moment(loanSchedules[i].due_date, "YYYY-MM-DD").subtract(1, 'days')
                let installmentRange = moment(installmentStartDate).format('MMM DD, YYYY') + ' - ' + moment(installmentEndDate).format('MMM DD, YYYY')
                eligibleInstallmentsForAmountUpdate.push({
                    label: numberToOrdinal(loanSchedules[i].installment_no) + ' Installment (' + installmentRange + ') - ' +
                        new Intl.NumberFormat('en-IN').format(loanSchedules[i].payable_amount.toFixed(2)) + ' BDT',
                    value: loanSchedules[i].installment_no
                })
            }
        }
        setInstallments(eligibleInstallmentsForAmountUpdate)
        GET_ORGANISATIONS()
        return ()=> {
            RETURN_TO_INITIAL_STATES_FOR_COMMON_REDUCER()
            CLEAR_STATES_RELATED_TO_UPDATE_INSTALLMENT_COMPONENT()
        }
    }, [])

    const numberToOrdinal = (number) => {
        const suffixes = ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'];

        if (number % 100 >= 11 && number % 100 <= 13) {
            return number + 'th'
        } else {
            return number + suffixes[number % 10]
        }
    }

    useEffect(() => {
        if (props.organisations) {
            const financiers = props.organisations;
            if (financiers.length > -1) {
                setFinanciers(financiers.map((financier) => {
                    return {
                        ...financier,
                        label: financier.name,
                        value: financier.pk
                    }
                }))
            }
        }
    }, [props.organisations])

    const update = (data) => {
        let payload = {
            ...data,
            "installment_no": data.installment_no.value,
            "authorized_by": data.authorized_by.label,
            "authorized_by_guid": data.authorized_by.value,
            "supporting_document": data['supporting_document'][0]
        }
        UPDATE_INSTALLMENT(payload, contractDetails.contract_id)
    }

    useEffect(() => {
        if (props.installmentUpdated && props.installmentUpdated === requestCycle.success) {
            toast.success(installmentSerialForSuccessMessage + " installment has been updated successfully!")
            closeInstallmentUpdateProcess()
            COLLECT_LOAN_DETAILS(contractDetails.contract_id)
            RETURN_TO_INITIAL_STATE()
        }
    }, [props.installmentUpdated])

    useEffect(() => {
        if (props.errorMessageForInstallmentUpdate) {
            showNotifications('error', props.errorMessageForInstallmentUpdate)
        }
    }, [props.errorMessageForInstallmentUpdate])

    return (
        <>
            <Form onSubmit={handleSubmit(update)}>
                <div className={'row g-3 mt-5'}>
                    <div className={'col-md-12'}>
                        <Form.Group>
                            <div>
                                <Form.Label>Select Installment <span className="required text-danger">*</span></Form.Label>
                            </div>
                            <Controller
                                control={control}
                                name={"installment_no"}
                                defaultValue={''}
                                rules={{required: infoRequiredText}}
                                render={( { onChange, onBlur, value, name, ref },
                                          { invalid, isTouched, isDirty }) => (
                                    <Select
                                        placeholder={'Select an installment'}
                                        classNamePrefix="react-select-sol-style"
                                        maxMenuHeight={200}
                                        value={value}
                                        isClearable={true}
                                        control={control}
                                        inputRef={ref}
                                        options={installments}
                                        isSearchable={true}
                                        noOptionsMessage={() => "No installments"}
                                        onChange={(selected, {action}) => {
                                            if (action === 'clear') {
                                                setInstallmentSerialForSuccessMessage('')
                                            }
                                            onChange(selected)
                                            if (selected) {
                                                setInstallmentSerialForSuccessMessage(numberToOrdinal(selected.value))
                                            }
                                        }}
                                    />
                                )}
                            />
                            {errors.installment_no && <div className="text-danger">
                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.installment_no.message}</div>}
                        </Form.Group>
                    </div>
                </div>
                <div className={'row g-3'}>
                    <div className={'col-md-6'}>
                        <Form.Group>
                            <div>
                                <Form.Label>Updated Amount (BDT) <span className="required text-danger">*</span></Form.Label>
                            </div>
                            <SolInput
                                name={"updated_amount"}
                                type={"number"}
                                min={"1"}
                                max={"300000"}
                                step={"0.01"}
                                placeholder={"Provide updated amount"}
                                autoComplete={"off"}
                                ref={register({
                                    required: infoRequiredText
                                })}
                            />
                            {errors.updated_amount && <div className="text-danger">
                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.updated_amount.message}</div>}
                        </Form.Group>
                    </div>
                    <div className={'col-md-6'}>
                        <Form.Group>
                            <div>
                                <Form.Label>Authorized By <span className="required text-danger">*</span></Form.Label>
                            </div>
                            <Controller
                                control={control}
                                name={"authorized_by"}
                                defaultValue={''}
                                rules={{required: infoRequiredText}}
                                render={( { onChange, onBlur, value, name, ref },
                                          { invalid, isTouched, isDirty }) => (
                                    <Select
                                        placeholder={'Select an authorization body'}
                                        classNamePrefix="react-select-sol-style"
                                        isDisabled={props.orgListLoading}
                                        isLoading={props.orgListLoading}
                                        maxMenuHeight={200}
                                        value={value}
                                        isClearable={true}
                                        control={control}
                                        inputRef={ref}
                                        options={financiers}
                                        isSearchable={true}
                                        noOptionsMessage={() => "No financiers"}
                                        onChange={(selected, {action}) => {
                                            onChange(selected)
                                        }}
                                    />
                                )}
                            />
                            {errors.authorized_by && <div className="text-danger">
                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.authorized_by.message}</div>}
                        </Form.Group>
                    </div>
                </div>
                <div className={'row g-3'}>
                    <div className={'col-md-6'}>
                        <Form.Group>
                            <div>
                                <Form.Label>Supporting Document <span className="required text-danger">*</span> {toolTipForDocumentField()}</Form.Label>
                            </div>
                            <SolInput
                                name={"supporting_document"}
                                type={"file"}
                                ref={register({
                                    required: infoRequiredText
                                })}
                                onClick={(e) => {
                                    // Clearing the file field value when user tries to re-upload
                                    setValue("supporting_document", "")
                                    clearErrors('supporting_document') // Clearing error to handle the case: user re-initiates file upload process after a wrong file upload, but this time he/she haven't uploaded a file
                                    setDisableUpdateButton(false)
                                }}
                                onChange={(e) => {
                                    if (e.target.files) {
                                        let value = e.target.files[0]
                                        if (value) {
                                            if (!["application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                                                "application/pdf", "application/vnd.ms-excel",
                                                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                                "image/jpeg", "image/webp", "image/png", "text/csv"].includes(value.type)) {
                                                setError('supporting_document', {'message': invalidFileType})
                                                setDisableUpdateButton(true)
                                            } else if (value.size/(1024 * 1024) > 10) {
                                                setError('supporting_document', {'message': invalidSize})
                                                setDisableUpdateButton(true)
                                            } else {
                                                clearErrors('supporting_document')
                                                setDisableUpdateButton(false)
                                            }
                                        }
                                    }
                                }}
                            />
                            {errors.supporting_document && <div className="text-danger">
                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.supporting_document.message}</div>}
                        </Form.Group>
                    </div>
                </div>
                <hr/>
                <div className={'row mt-3'}>
                    <div className={"col-md-12"}>
                        <Stack
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="flex-start"
                            spacing={2}
                        >
                            <Button variant={"outline-dark"} type={"button"} size={'lg'}
                                    disabled={props.installmentUpdateInProgress}
                                    onClick={closeInstallmentUpdateProcess}>
                                Cancel
                            </Button>
                            <Button variant="warning" size={'lg'} type="submit"
                                    disabled={props.installmentUpdateInProgress || disableUpdateButton}>
                                {props.installmentUpdateInProgress? <><Spinner animation={'border'} size={'sm'} variant={'light'}/></>:null} Update
                            </Button>
                        </Stack>
                    </div>
                </div>
            </Form>
        </>
    )
}

UpdateInstallment.propTypes = {}

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        organisations: state.commonReducer.organisations,
        orgListLoading: state.commonReducer.orgListLoading,
        installmentUpdated: state.contractManagementReducer.installmentUpdated,
        installmentUpdateInProgress: state.contractManagementReducer.installmentUpdateInProgress,
        errorMessageForInstallmentUpdate: state.contractManagementReducer.errorMessageForInstallmentUpdate
    }
}

export default connect(mapStateToProps, { ...actions, ...commonActions })(UpdateInstallment);