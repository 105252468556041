import React, { useEffect } from 'react';
import { useState } from 'react';
import {connect} from 'react-redux';
import { actions } from '../../../actions';
import TableContainer from "@mui/material/TableContainer";
import { Table } from "react-bootstrap"
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";


const AdditionalInfoWithFeesAndCharges = ({ contractDetails, tableHeaderLeftAlignedColumnStyling,
                                              tableLeftAlignedContentStyling,  ...props }) => {
    const [language, setLanguage] = useState("EN");

    const [platformFeePercentage, setPlatformFeePercentage] = useState(null);
    const [associatedOrgOrGarage, setAssociatedOrgOrGarage] = useState(null);
    const [referenceId, setReferenceId] = useState(null);
    const [leasingFeeReceivableBy, setLeasingFeeReceivableBy] = useState(null)

    const {
        // Will add action here, if needed
    } = props;

    useEffect(() => {
        setLanguage(props.language)

        // Fees and charges
        setPlatformFeePercentage(contractDetails.platform_fee_percentage)
        setLeasingFeeReceivableBy(contractDetails.solshare_revenue === 'leasing_fee' ? 'ME SOLshare LTD.' : contractDetails.leasing_fee_receivable_by)

        // Additional info
        setAssociatedOrgOrGarage(contractDetails.associated_organization? contractDetails.associated_organization:'-')
        setReferenceId(contractDetails.reference_id? contractDetails.reference_id:'-')
    }, [])

    return (
        <>
            <div className={'row mt-10'}>
                <div className={'col-md-1'}></div>
                <div className={'col-md-5'}>
                    <h6><strong>Fees & Charges</strong></h6>
                </div>
                <div className={'col-md-5'}>
                    <h6><strong>Additional Info</strong></h6>
                </div>
                <div className={'col-md-1'}></div>
            </div>
            <div className={'row mt-5'}>
                <div className={'col-md-1'}></div>
                <div className={'col-md-5'}>
                    <TableContainer>
                        <Table bordered={true} aria-label="spanning table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={tableHeaderLeftAlignedColumnStyling}>Platform Fee (%)</TableCell>
                                    <TableCell style={tableHeaderLeftAlignedColumnStyling}>Leasing Fee Receivable By</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell style={tableLeftAlignedContentStyling}>
                                        <h6><strong>{platformFeePercentage}</strong></h6>
                                    </TableCell>
                                    <TableCell style={tableLeftAlignedContentStyling}>
                                        <h6><strong>{leasingFeeReceivableBy}</strong></h6>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div className={'col-md-5'}>
                    <TableContainer>
                        <Table bordered aria-label="spanning table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={tableHeaderLeftAlignedColumnStyling}>Associated Organization or Garage</TableCell>
                                    <TableCell style={tableHeaderLeftAlignedColumnStyling}>Reference ID</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell style={tableLeftAlignedContentStyling}>
                                        <h6><strong>{associatedOrgOrGarage}</strong></h6>
                                    </TableCell>
                                    <TableCell style={tableLeftAlignedContentStyling}>
                                        <h6><strong>{referenceId}</strong></h6>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div className={'col-md-1'}></div>
            </div>
        </>
    )
}

AdditionalInfoWithFeesAndCharges.propTypes = {}

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations
    }
}

export default connect(mapStateToProps, { ...actions})(AdditionalInfoWithFeesAndCharges);