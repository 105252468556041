import React, { useEffect } from 'react';
import { useState } from 'react';
import {connect} from 'react-redux';
import { actions } from '../../../actions';
import TableContainer from "@mui/material/TableContainer";
import { Table } from "react-bootstrap"
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";


const NomineeInfo = ({ contractDetails, tableHeaderLeftAlignedColumnStyling, tableLeftAlignedContentStyling,  ...props }) => {
    const [language, setLanguage] = useState("EN");

    const [nomineeName, setNomineeName] = useState('');
    const [nomineePhone, setNomineePhone] = useState('');
    const [nomineeType, setNomineeType] = useState('')
    const [nomineeGarageName, setNomineeGarageName] = useState('')
    const [nomineeAddress, setNomineeAddress] = useState('')
    // Nominee Details key has been used at new data format - Noor Reza, 27th Dec, 2023, 2:32 PM
    const [nomineeDetails, setNomineeDetails] = useState('')

    const {
        // Will add action here, if needed
    } = props;

    useEffect(() => {
        setLanguage(props.language)

        setNomineeName(contractDetails.nominee_name)
        setNomineePhone(contractDetails.nominee_phone)

        const nomineeType = contractDetails.nominee_user_details? contractDetails.nominee_user_details.user_type.split("_").map((partOfType) => {
            return partOfType.charAt(0).toUpperCase() + partOfType.slice(1)
        }).join(' ') : ''
        setNomineeType(nomineeType)

        const nomineeGarage = contractDetails.nominee_garage ? contractDetails.nominee_garage.name : ''
        setNomineeGarageName(nomineeGarage)
        setNomineeDetails(contractDetails.nominee_details? contractDetails.nominee_details:'')

        let nomineeAddress = ''
        if (contractDetails.nominee_garage) {
            if (contractDetails.nominee_garage.upazila && contractDetails.nominee_garage.district) {
                nomineeAddress = contractDetails.nominee_garage.upazila + ', ' + contractDetails.nominee_garage.district
            } else if (contractDetails.nominee_garage.district) {
                nomineeAddress = contractDetails.nominee_garage.district
            } else if (contractDetails.nominee_garage.upazila) {
                nomineeAddress = contractDetails.nominee_garage.upazila
            }
        }
        setNomineeAddress(nomineeAddress)
    }, [])

    return (
        <>
            <div className={'row mt-10'}>
                <div className={'col-md-1'}></div>
                <div className={'col-md-10'}>
                    <h6><strong>Nominee</strong></h6>
                </div>
                <div className={'col-md-1'}></div>
            </div>
            {nomineeName || nomineePhone || nomineeDetails || nomineeType || nomineeGarageName || nomineeAddress? <>
                <div className={'row mt-5'}>
                    <div className={'col-md-1'}></div>
                    <div className={'col-md-10'}>
                        <TableContainer>
                            <Table bordered={true} aria-label="spanning table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={tableHeaderLeftAlignedColumnStyling}>Name</TableCell>
                                        <TableCell style={tableHeaderLeftAlignedColumnStyling}>Contact</TableCell>
                                        <TableCell style={tableHeaderLeftAlignedColumnStyling}>Address</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell style={tableLeftAlignedContentStyling}>
                                            <h6><strong>{nomineeName? nomineeName:'-'}</strong></h6>
                                        </TableCell>
                                        <TableCell style={tableLeftAlignedContentStyling}>
                                            <h6><strong>{nomineePhone? nomineePhone:'-'}</strong></h6>
                                        </TableCell>
                                        <TableCell style={tableLeftAlignedContentStyling}>
                                            {nomineeDetails? <h6><strong>{nomineeDetails}</strong></h6>:<>
                                                {/* Keeping the following portion, just to support old json data -
                                                Noor Reza, 27th December, 2023 2:36 PM */}
                                                {nomineeType && nomineeGarageName && nomineeAddress? <>
                                                    <h6><strong>{nomineeType}, {nomineeGarageName}, {nomineeAddress}</strong></h6>
                                                </>:<>
                                                    {!nomineeType && nomineeGarageName && nomineeAddress? <>
                                                        <h6><strong>{nomineeGarageName}, {nomineeAddress}</strong></h6>
                                                    </>:<>
                                                        {nomineeType && !nomineeGarageName && nomineeAddress? <>
                                                            <h6><strong>{nomineeType}, {nomineeAddress}</strong></h6>
                                                        </>:<>
                                                            {nomineeType && nomineeGarageName && !nomineeAddress? <>
                                                                <h6><strong>{nomineeType}, {nomineeGarageName}</strong></h6>
                                                            </>:<>
                                                                {!nomineeType && !nomineeGarageName && nomineeAddress? <>
                                                                    <h6><strong>{nomineeAddress}</strong></h6>
                                                                </>:<>
                                                                    {!nomineeType && nomineeGarageName && !nomineeAddress? <>
                                                                        <h6><strong>{nomineeGarageName}</strong></h6>
                                                                    </>:<>
                                                                        <h6><strong>{nomineeType}</strong></h6>
                                                                    </>}
                                                                </>}
                                                            </>}
                                                        </>}
                                                    </>}
                                                </>}
                                            </>}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    <div className={'col-md-1'}></div>
                </div>
            </>:<>
                <div className={'row'}>
                    <div className={'col-md-1'}></div>
                    <div className={'col-md-10'}>
                        There is no nominee
                    </div>
                    <div className={'col-md-1'}></div>
                </div>
            </>}
        </>
    )
}

NomineeInfo.propTypes = {}

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations
    }
}

export default connect(mapStateToProps, { ...actions})(NomineeInfo);