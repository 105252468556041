import { IconButton } from '@material-ui/core';
import FilterListIcon from "@material-ui/icons/FilterList";
import React, { useEffect, useState } from 'react';
import {connect } from 'react-redux';
import { Toolbar } from '../../../../components/compoundToolBar/compoundToolBar';
import ContentWrapper from '../../../../components/contentWrapper/contentWrapper';
import { useStylesIconButton } from '../../../../utils/utilityFunctions';
import { DataTableContainer, DataTable} from '../../../../components';
import { Form, Button } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import {actions as commonActions} from '../../../commonReduxActions/actions';
import { actions } from '../../actions';
import { withRoles } from '../../../../router/SecuredRoute';
import { useSubheader } from '../../../../../_metronic/layout';
import DateTimeFormat from '../../../../utils/dateFormat/DateTimeFormat';
import {checkAuthorization} from "../../../auth/authorization";
import {getServiceName} from "../../../../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../../../../utils/roleRelatedValues/featureNames";
import {getActionName} from "../../../../utils/roleRelatedValues/actionNames";
import {customStylesForSingleSelectField} from "../../../../utils/reactSelectRelated/customStylesForSingleSelect";
import {Skeleton, Typography} from "@mui/material";
import {showNotifications} from "../../../../utils/notification";


const SupplierInfo = props => {
    const {register, errors, control, handleSubmit, setValue, getValues} = useForm();
    const type = [
        {value: "bms", label: "BMS"}, {value: "battery", label: "Battery"}
    ]
    const [filter, setFilter] = useState(true);
    const [showBmsVendorColumns, setShowBmsVendorColumns] = useState(true);
    const [vendors, setVendors] = useState(undefined);
    const classes = useStylesIconButton();
    const subheader = useSubheader()

    // Permission related
    const batteryServiceName = getServiceName('batteryService')
    const vendorFeatureName = getFeatureName('vendorFeature')
    const createActionName = getActionName('createAction')

    const [loaderText, setLoaderText] = useState('Collecting BMS vendors...') // By default, BMS vendors will be collected

    const {
        GET_VENDOR_LIST,
        RETURN_TO_INITIAL_STATE,
        RETURN_TO_INITIAL_STATES_FOR_COMMON_REDUCER,
    } = props;

    useEffect(() => {
        if (checkAuthorization(props.authorization, batteryServiceName, vendorFeatureName, [createActionName])) {
            subheader.setActionButtons(
                <div className="d-flex align-item-center justify-content-end">
                    <Button variant="warning" size={'sm'} type="button" style={{
                        backgroundColor: '#F18D00 !important',
                    }} classes={classes} onClick={() => {
                        props.history.push({
                            pathname: '/batteries/vendors/create',
                            purpose: 'vendorCreate'
                        })
                    }}>
                        <i className="fa fa-random" />Create Vendor
                    </Button>

                </div>
            )
        } else {
            subheader.setActionButtons(null)
        }
        subheader.setBreadCrumbComponent(null)
        setValue('productType', type[0])
        GET_VENDOR_LIST({
            type: type[0].value
        })

        return () => {
            RETURN_TO_INITIAL_STATE()
            RETURN_TO_INITIAL_STATES_FOR_COMMON_REDUCER()
        }
    }, [])

    const filterToggle =()=>{
        setFilter(!filter);
    }

    const resetFilter=()=>{
        if (getValues().productType.value !== type[0].value && !showBmsVendorColumns) {
            setLoaderText('Collecting BMS vendors...')
            GET_VENDOR_LIST({
                type: type[0].value
            })
            setShowBmsVendorColumns(true)
        }
        setValue('productType', type[0])
    }

    const batteryVendorListColumns = [
        {
            field: 'name',
            title: 'Name',
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                // color: '#F18D00',
                paddingLeft: '0'
            },
            headerStyle: {
                paddingLeft: '0'
            },
            // disableClick: true,
        },
        {
            field: 'product_type',
            title: 'Vendor type',
            emptyValue: ()=>{
                return "N/A"
            },
            lookup: {
                'bms': 'BMS',
                'battery': "Battery"
            }
            // disableClick: true,
        },
        {
            field: 'created_at',
            title: 'Created on',
            emptyValue: () => {
                return "N/A"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData)=>{
                return new DateTimeFormat(rowData.created_at).localDisplayWithoutTime
            
            }
            // disableClick: true,
        }
    ]

    const bmsVendorListColumns = [
        {
            field: 'name',
            title: 'Name',
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                // color: '#F18D00',
                paddingLeft: '0'
            },
            headerStyle: {
                paddingLeft: '0'
            },
            // disableClick: true,
        },
        {
            field: 'bms_vendor_code',
            title: 'Code',
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                // color: '#F18D00',
                paddingLeft: '0'
            },
            headerStyle: {
                paddingLeft: '0'
            },
            // disableClick: true,
        },
        {
            field: 'product_type',
            title: 'Vendor type',
            emptyValue: ()=>{
                return "N/A"
            },
            lookup: {
                'bms': 'BMS',
                'battery': "Battery"
            }
            // disableClick: true,
        },
        {
            field: 'created_at',
            title: 'Created on',
            emptyValue: () => {
                return "N/A"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData)=>{
                return new DateTimeFormat(rowData.created_at).localDisplayWithoutTime

            }
            // disableClick: true,
        },
    ]

    const filterTheList = (data) => {
        GET_VENDOR_LIST({
            type: data.productType.value
        })
        if (data.productType.value === type[0].value) {
            setLoaderText('Collecting BMS vendors...')
            setShowBmsVendorColumns(true)
        } else {
            setLoaderText('Collecting Battery vendors...')
            setShowBmsVendorColumns(false)
        }
    }

    useEffect(() => {
        if (props.vendorList && props.vendorList.length > -1) {
            const vendors = props.vendorList
            setVendors(vendors.map((vendor) => {
                return {
                    ...vendor
                }
            }))
        } else {
            setVendors(undefined)
        }
    }, [props.vendorList])

    useEffect(() => {
        if (props.errorForVendorListCollection) {
            showNotifications('error', props.errorForVendorListCollection)
            setVendors([])
        }
    }, [props.errorForVendorListCollection])

    return (
        <ContentWrapper showBackButton={true} pageTitle={"Vendor List"} showCardHeader={false}>
            <div>
                <Toolbar>
                    <Toolbar.Title>
                        <h1><strong>Vendors</strong></h1>
                    </Toolbar.Title>
                    <Toolbar.ToolbarContainer>
                        <Toolbar.ToolbarContainer.Items>
                            <IconButton onClick={filterToggle} classes={classes}>
                                <FilterListIcon color="disabled" fontSize="large"/>
                            </IconButton>
                        </Toolbar.ToolbarContainer.Items>
                    </Toolbar.ToolbarContainer>
                </Toolbar>
                <hr/>
            </div>

            {filter? <>
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <Form onSubmit={handleSubmit(filterTheList)}>
                            <div className={'row g-3'}>
                                <div className={'col-md-4'}>
                                    <Form.Group>
                                        <div>
                                            <Form.Label>Product type</Form.Label>
                                        </div>
                                        <Controller
                                            control={control}
                                            name={"productType"}
                                            rules={{ required: "Above information is required!" }}
                                            defaultValue={''}
                                            render={( { onChange, onBlur, value, name, ref },
                                                      { invalid, isTouched, isDirty }) => (
                                                <Select
                                                    placeholder={'Select a Product type'}
                                                    classNamePrefix="react-select-sol-style"
                                                    maxMenuHeight={200}
                                                    value={value}
                                                    isClearable={false}
                                                    isDisabled={props.listLoading}
                                                    options={type}
                                                    isSearchable={true}
                                                    control={control}
                                                    styles={customStylesForSingleSelectField}
                                                    onChange={(selected, {action}) => {
                                                        onChange(selected)
                                                    }}
                                                />
                                            )}
                                        />
                                        {errors.productType && <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.productType.message}</div>}
                                    </Form.Group>
                                </div>
                            </div>
                            <div className={'row'}>
                                <div className={"col-md-12"}>
                                    <Button variant="warning" size={'sm'} type="submit"
                                            disabled={props.listLoading}>
                                        Apply filter
                                    </Button>
                                    <Button variant="outline-dark" type={"button"} size={'sm'}
                                            onClick={resetFilter} className={'ml-3'}
                                            disabled={props.listLoading}>
                                        Reset filter
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
                <hr/></>:null}

            <div className={'row'}>
                <div className={'col-md-12'}>
                    {!props.listLoading && vendors?
                        <DataTableContainer>
                            <DataTable
                                language={'EN'}
                                noDataAvailableMessageInBangla={'কোন ব্যাটারী নেই'}
                                noDataAvailableMessageInEnglish={'No vendors available'}
                                columns={showBmsVendorColumns? bmsVendorListColumns:batteryVendorListColumns}
                                data={vendors}
                                showToolbar={false}
                                asyncPagination={false}
                                overrideCustomActions={true}
                            />
                        </DataTableContainer> :<>
                            <Typography variant="h5">
                                <Skeleton className={"d-flex justify-content-center align-items-center"}
                                          variant="rect" sx={{ marginTop: '30px', color: '#F18D00' }} width={'100%'}
                                          height={536}>
                                    {loaderText}
                                </Skeleton>
                            </Typography>
                        </>}
                </div>
            </div>
        </ContentWrapper>
    );
};

SupplierInfo.propTypes = {
    
};

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        isLoading: state.batteryReducer.isLoading,
        listLoading: state.commonReducer.listLoading,
        vendorList: state.commonReducer.vendorList,
        errorForVendorListCollection: state.commonReducer.errorForVendorListCollection
    }
}

export default connect(mapStateToProps, {...actions, ...commonActions})(withRoles(SupplierInfo));