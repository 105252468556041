export const customStylesForSingleSelectField = {
    option: (styles, data) => {
        return {
            ...styles,
            backgroundColor: data.isSelected || data.isFocused? '#F18D00': 'white'
        }
    },
    control: (styles, { isFocused }) => ({
        ...styles,
        borderColor: isFocused ? '#F18D00' : styles.borderColor,
        boxShadow: isFocused ? '0 0 0 1px #F18D00' : styles.boxShadow, // Adds focus effect
        '&:hover': {
            borderColor: '#F18D00'
        }
    })
}