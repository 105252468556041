import React, { useEffect } from 'react';
import { useState } from 'react';
import {connect} from 'react-redux';
import { SolInput } from '../../../../../components';
import { actions } from '../../../actions';
import { actions as commonActions } from '../../../../commonReduxActions/actions';
import {toast} from "react-toastify";
import {Button, Spinner, Form} from "react-bootstrap";
import Stack from "@mui/material/Stack";
import {Controller, useForm} from "react-hook-form";
import Select from "react-select";
import {requestCycle} from "../../../../borrowers/utils";
import {showNotifications} from "../../../../../utils/notification";


const EditContract = ({ contractID, contractType, smartBatteryOfTheContract, smartBatteryType, smartBatteryDetails, defaultPerRentPayment, autoPaymentEnabled, closeEditContractProcess, ...props }) => {
    const {register, errors, control, handleSubmit, setValue} = useForm()
    const [language, setLanguage] = useState("EN");
    const [smartBatteries, setSmartBatteries] = useState([]);
    const [showSelectedSmartBatteryDetails, setShowSelectedSmartBatteryDetails] = useState(false);
    const [selectedSmartBattery, setSelectedSmartBattery] = useState('');

    // Following option variable has been used exactly at the "Operational logs" at the Contract details page
    const batteryUsageTypes = [
        {
            label: 'Service Battery',
            value: 'service'
        },
        {
            label: 'Primary Battery',
            value: 'primary'
        }
    ]

    const autoPaymentStates = [
        {
            label: 'Allowed',
            value: 'allowed'
        },
        {
            label: 'Not Allowed',
            value: 'not_allowed'
        }
    ]

    const customStylesForAutoPaymentStateSelection = {
        option: (styles, data) => {
            return {
                ...styles,
                color: data.value === 'allowed'? '#0b6e4f': '#cb152b'
            }
        },
        singleValue: (styles, selectedData) => {
            return {
                ...styles,
                color: selectedData.data.value === 'allowed'? '#0b6e4f': '#cb152b'
            }
        }
    }

    const infoRequiredText = "Above information is required!"

    const {
        GET_DEVICE_LIST,
        EDIT_CONTRACT,
        COLLECT_LOAN_DETAILS,
        CLEAR_STATES_RELATED_TO_EDIT_CONTRACT_COMPONENT,
        RETURN_TO_INITIAL_STATE
    } = props;

    useEffect(() => {
        setLanguage(props.language)
        GET_DEVICE_LIST({'purpose': 'contract' })

        let defaultBatteryType = batteryUsageTypes.filter((item) => item.value === smartBatteryType)
        if (defaultBatteryType[0]) {
            setValue('battery_usage', defaultBatteryType[0])
        } else {
            setValue('battery_usage', batteryUsageTypes[1])
        }

        if (autoPaymentEnabled) {
            setValue('auto_payment', autoPaymentStates[0])
        } else {
            setValue('auto_payment', autoPaymentStates[1])
        }

        return ()=> {
            CLEAR_STATES_RELATED_TO_EDIT_CONTRACT_COMPONENT()
        }
    }, [])

    useEffect(() => {
        if (props.deviceList) {
            const devices = props.deviceList
            let updatedDeviceData = devices.map((item) => {
                let smartBattery = {
                    ...item,
                    label: item.serial_number,
                    value: item.serial_number
                }
                return {...smartBattery}
            })
            let existingSmartBattery = {
                battery_details: smartBatteryDetails,
                label: smartBatteryOfTheContract,
                value: smartBatteryOfTheContract
            }
            updatedDeviceData.push(existingSmartBattery)
            setSmartBatteries(updatedDeviceData)
            setValue('device_serial_number', existingSmartBattery)
            setSelectedSmartBattery(existingSmartBattery)
            setShowSelectedSmartBatteryDetails(true)
        } else {
            setSmartBatteries([])
        }
    }, [props.deviceList])

    const editTheContract = (data) => {
        let payload = {
            'device_serial_number': data.device_serial_number?.value,
            'battery_type': data.battery_usage?.value,
            'enable_auto_payment': data.auto_payment?.value === 'allowed'
        }
        if (contractType === 'rent') {
            payload['per_rent_payable'] = parseFloat(data.per_rent_payable)
        }
        EDIT_CONTRACT({...payload}, contractID)
    }

    useEffect(() => {
        if (props.contractEdited && props.contractEdited === requestCycle.success) {
            closeEditContractProcess()
            toast.success("Contract is edited successfully!")
            COLLECT_LOAN_DETAILS(contractID)
            RETURN_TO_INITIAL_STATE()
        }
    }, [props.contractEdited])

    useEffect(() => {
        if (props.errorMessageForContractEdit) {
            showNotifications("error", props.errorMessageForContractEdit)
        }
    }, [props.errorMessageForContractEdit])

    // ---- Edit: Related to contract edit ---- //

    return (
        <>
            <Form onSubmit={handleSubmit(editTheContract)}>
                <div className={'row g-3'}>
                    <div className={'col-md-6'}>
                        <Form.Group>
                            <div>
                                <Form.Label>Select Smart Battery <span className="required text-danger">*</span></Form.Label>
                            </div>
                            <Controller
                                control={control}
                                name={"device_serial_number"}
                                defaultValue={''}
                                rules={{required: infoRequiredText}}
                                render={( { onChange, onBlur, value, name, ref },
                                          { invalid, isTouched, isDirty }) => (
                                    <Select
                                        placeholder={'Select a smart battery'}
                                        classNamePrefix="react-select-sol-style"
                                        isDisabled={props.listLoading}
                                        isLoading={props.listLoading}
                                        maxMenuHeight={200}
                                        value={value}
                                        isClearable={true}
                                        control={control}
                                        inputRef={ref}
                                        options={smartBatteries}
                                        isSearchable={true}
                                        noOptionsMessage={() => "No smart batteries"}
                                        onChange={(selected, {action}) => {
                                            if (action === 'clear') {
                                                setShowSelectedSmartBatteryDetails(false)
                                                setSelectedSmartBattery('')
                                            }
                                            onChange(selected)
                                            if (selected) {
                                                setShowSelectedSmartBatteryDetails(true)
                                                setSelectedSmartBattery(selected)
                                            }
                                        }}
                                    />
                                )}
                            />
                            {showSelectedSmartBatteryDetails && selectedSmartBattery && selectedSmartBattery.battery_details? <>
                                <div className="small text-muted">
                                    <strong>{selectedSmartBattery.battery_details.battery_vendor}</strong>,&nbsp;
                                    <strong>{selectedSmartBattery.battery_details.nameplate_capacity}Ah</strong>,&nbsp;
                                    <strong>{selectedSmartBattery.battery_details.nameplate_voltage}V</strong></div>
                            </>:null}
                            {errors.device_serial_number && <div className="text-danger">
                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.device_serial_number.message}</div>}
                        </Form.Group>
                    </div>
                    <div className={'col-md-6'}>
                        <Form.Group>
                            <div>
                                <Form.Label>Battery is used as <span className="required text-danger">*</span></Form.Label>
                            </div>
                            <Controller
                                control={control}
                                name={"battery_usage"}
                                defaultValue={''}
                                rules={{required: infoRequiredText}}
                                render={( { onChange, onBlur, value, name, ref },
                                          { invalid, isTouched, isDirty }) => (
                                    <Select
                                        placeholder={'Select battery usage'}
                                        classNamePrefix="react-select-sol-style"
                                        maxMenuHeight={200}
                                        value={value}
                                        isClearable={true}
                                        control={control}
                                        inputRef={ref}
                                        options={batteryUsageTypes}
                                        isSearchable={true}
                                        onChange={(selected, {action}) => {
                                            onChange(selected)
                                        }}
                                    />
                                )}
                            />
                            {errors.battery_usage && <div className="text-danger">
                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.battery_usage.message}</div>}
                        </Form.Group>
                    </div>
                </div>
                <div className={'row g-3'}>
                    {contractType === 'rent'? <>
                        <div className={'col-md-6'}>
                            <Form.Group>
                                <div>
                                    <Form.Label>Per Rent Payable <span className="required text-danger">*</span></Form.Label>
                                </div>
                                <SolInput
                                    name={"per_rent_payable"}
                                    type={"number"}
                                    min={"0"}
                                    max={"300000"}
                                    step={"0.01"}
                                    placeholder={"Provide per rent amount"}
                                    defaultValue={defaultPerRentPayment}
                                    autoComplete={"off"}
                                    ref={register({
                                        required: infoRequiredText
                                    })}
                                />
                                {errors.per_rent_payable && <div className="text-danger">
                                    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.per_rent_payable.message}</div>}
                            </Form.Group>
                        </div>
                    </>:null}
                    <div className={'col-md-6'}>
                        <Form.Group>
                            <div>
                                <Form.Label>Auto Payment <span className="required text-danger">*</span></Form.Label>
                            </div>
                            <Controller
                                control={control}
                                name={"auto_payment"}
                                defaultValue={''}
                                rules={{required: infoRequiredText}}
                                render={( { onChange, onBlur, value, name, ref },
                                          { invalid, isTouched, isDirty }) => (
                                    <Select
                                        placeholder={'Select an option'}
                                        classNamePrefix="react-select-sol-style"
                                        maxMenuHeight={200}
                                        value={value}
                                        isClearable={true}
                                        control={control}
                                        inputRef={ref}
                                        options={autoPaymentStates}
                                        isSearchable={true}
                                        styles={customStylesForAutoPaymentStateSelection}
                                        onChange={(selected, {action}) => {
                                            onChange(selected)
                                        }}
                                    />
                                )}
                            />
                            {errors.auto_payment && <div className="text-danger">
                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.auto_payment.message}</div>}
                        </Form.Group>
                    </div>
                </div>
                <hr/>
                <div className={'row mt-3'}>
                    <div className={"col-md-12"}>
                        <Stack
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="flex-start"
                            spacing={2}
                        >
                            <Button variant={"outline-dark"} type={"button"} size={'lg'}
                                    disabled={props.contractEditInProgress}
                                    onClick={closeEditContractProcess}>
                                Cancel
                            </Button>
                            <Button variant="warning" size={'lg'} type="submit"
                                    disabled={props.contractEditInProgress || props.listLoading} style={{
                                backgroundColor: '#F18D00'
                            }}>
                                {props.contractEditInProgress? <><Spinner animation={'border'} size={'sm'}
                                                                          variant={'light'}/></>:null} Save
                            </Button>
                        </Stack>
                    </div>
                </div>
            </Form>
        </>
    )
}

EditContract.propTypes = {}

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        deviceList: state.commonReducer.deviceList,
        listLoading: state.commonReducer.listLoading,
        contractEdited: state.contractManagementReducer.contractEdited,
        contractEditInProgress: state.contractManagementReducer.contractEditInProgress,
        errorMessageForContractEdit: state.contractManagementReducer.errorMessageForContractEdit
    }
}

export default connect(mapStateToProps, { ...actions, ...commonActions })(EditContract);