export const customStylesForMultiSelectField = {
    // Destructuring isFocused directly in the argument list is more efficient than accessing it as a property of the argument object (data),
    //  as we have used only isFocused in the return statement
    option: (styles, { isFocused }) => {
        return {
            ...styles,
            backgroundColor: isFocused? '#F18D00': 'white',
            color: isFocused? 'white': 'inherit'
        }
    },
    control: (styles, { isFocused }) => ({
        ...styles,
        borderColor: isFocused ? '#F18D00' : styles.borderColor,
        boxShadow: isFocused ? '0 0 0 1px #F18D00' : styles.boxShadow, // Adds focus effect
        '&:hover': {
            borderColor: '#F18D00'
        }
    })
}