import React, {useEffect, useState} from 'react';
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import {Toolbar} from "../components";
import {connect} from "react-redux";
import {useLocation} from "react-router";
import {actions} from "../../actions";
import {actions as commonActions} from "../../../commonReduxActions/actions";
import {useSubheader} from "../../../../../_metronic/layout";
import {withRoles} from "../../../../router/SecuredRoute";
import Alert from '@mui/material/Alert';
import {useMediaQuery} from "react-responsive";
import EvOrDriverSearch from './evOrDriverSearch/evOrDriverSearch'


const Search = (props) => {
    const [searchParameter, setSearchParameter] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [title, setTitle] = useState('');
    const subHeader = useSubheader();
    const [language, setLanguage] = useState(null);
    const location = useLocation()
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

    const {
        RETURN_TO_INITIAL_STATE_FOR_SEARCH,
        RETURN_TO_INITIAL_STATES_FOR_COMMON_REDUCER
    } = props;

    useEffect(() => {
        setLanguage(props.language)
        subHeader.setBreadCrumbComponent("search")
        subHeader.setActionButtons(null)
        if (location.searchParam) {
            setSearchParameter(location.searchParam)
            setSearchValue(JSON.parse(location.searchValue))
        } else if (sessionStorage.getItem("searchParam")) {
            setSearchParameter(sessionStorage.getItem("searchParam"))
            setSearchValue(JSON.parse(sessionStorage.getItem("searchValue")))
        } else {
            setSearchParameter('')
            setSearchValue('')
        }
        return ()=>{
            RETURN_TO_INITIAL_STATE_FOR_SEARCH()
            RETURN_TO_INITIAL_STATES_FOR_COMMON_REDUCER()
        }
    }, [])

    useEffect(() => {
        if (location.searchParam) {
            setSearchParameter(location.searchParam)
            setSearchValue(JSON.parse(location.searchValue))
        }
    }, [location])

    useEffect(() => {
        if (searchParameter) {
            let breadCrumbText = 'EV number ' + searchParameter
            let title = 'Details for EV number ' + searchParameter
            setTitle(title)
            subHeader.setBreadCrumbComponent(
                <>
                    <h5 className="text-dark font-weight-bold my-2 mr-5"><a onClick={() =>
                    {props.history.push({pathname: "/search"})}}>Search</a>&nbsp;&nbsp;/&nbsp;&nbsp;{breadCrumbText}</h5>
                </>
            )
        } else {
            subHeader.setBreadCrumbComponent(
                <>
                    <h5 className="text-dark font-weight-bold my-2 mr-5"><a onClick={() =>
                    {props.history.push({pathname: "/search"})}}>Search</a></h5>
                </>
            )
        }
    }, [searchParameter])

    return (
        <ContentWrapper showCardHeader={false} showBackButton={false} isLoading={props.isLoading}
                        serverError={false} permissionDenied={false} pageTitle={title}
                        statusCode={props.statusCode}>
            {searchParameter? <>
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        {isTabletOrMobile? <>
                            <h1><strong>{title}</strong></h1>
                        </>:<>
                            <Toolbar>
                                <Toolbar.Title>
                                    <h1><strong>{title}</strong></h1>
                                </Toolbar.Title>
                            </Toolbar>
                        </>}
                    </div>
                </div>
                <hr/>
                <EvOrDriverSearch searchParam={searchParameter} searchValue={searchValue}/>
            </>:<>
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <Alert severity="warning"><h5>Please provide a search parameter (Driver name or EV)!</h5></Alert>
                    </div>
                </div>
            </>}
        </ContentWrapper>
    );
};

Search.propTypes = {};

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        isSolshareUser: state.auth.is_solshare_user,
        collectingBorrowers: state.commonReducer.collectingBorrowers,
    }
}

export default connect(mapStateToProps, {...actions, ...commonActions})(withRoles(Search));
