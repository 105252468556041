import React, {useEffect, useState} from 'react';
// import PropTypes from 'prop-types';
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import DataTable from "../../../../components/dataTable/DataTable";
import {Toolbar} from "../components";
import {connect} from "react-redux";
import {actions} from "../../actions";
import {Button, Form, Spinner} from "react-bootstrap";
import {IconButton} from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import {SolInput} from "../../../../components/SolStyledComponents/components";
import {makeStyles} from "@material-ui/styles";
import {DataTableContainer} from '../../utils'
import {LoadingSpinner} from '../../../../components/LoadingSpinnerForDataTable'
import {useSubheader} from "../../../../../_metronic/layout";
import {parseParams} from "../../../../utils/utilityFunctions";
import {withRoles} from "../../../../router/SecuredRoute";
import {getServiceName} from "../../../../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../../../../utils/roleRelatedValues/featureNames";
import {getActionName} from "../../../../utils/roleRelatedValues/actionNames";
import {checkAuthorization} from "../../../auth/authorization";
import {GenericModal} from "../../../../components/genericModal/genericModal";
import {showNotifications} from "../../../../utils/notification";
import {toast} from "react-toastify";
import {requestCycle} from "../../../rentLog/utils";
import moment from "moment";
import { ExportToCsv } from 'export-to-csv';
import {useForm} from "react-hook-form";
import Stack from "@mui/material/Stack";
import AccessManagementForSmartDongles from "./accessManagementForSmartDongles"
import EventsHistory from "../../../../components/eventHistoryModal/eventHistoryModal"
import parse from 'html-react-parser';


const useStylesIconButton = makeStyles({
    root: {
        marginTop: '0',
        paddingTop: '4px',
    },
});

const SmartDongleList = (props) => {
    const {register, errors, clearErrors, handleSubmit, setValue} = useForm();
    const subheader= useSubheader();

    const [showFilter, setShowFilter] = useState(true);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [csvUploadInProgress, setCsvUploadInProgress] = useState(false);
    const [fileUploaded, setFileUploaded] = useState(false);
    const [fileIsInvalid, setFileIsInvalid] = useState(false);
    const [uploadedContent, setUploadedContent] = useState([]);
    const [showFileMandatoryMessage, setShowFileMandatoryMessage] = useState(false);

    const [showSerialNumberGenerationModal, setShowSerialNumberGenerationModal] = useState(false);

    const [smartDongleList, setSmartDongleList] = useState([]);
    const [language, setLanguage] = useState('EN');
    const [authorization, setAuthorization] = useState(null);
    const [page, setPage] = useState(1);
    const [filterApplied, setFilterApplied] = useState(false);
    const [queryParams, setQueryParams] = useState(null);
    const [initialfilterParam, setInitialfilterParam] = useState(null);
    const [filterParamForApiCall, setFilterParamForApiCall] = useState(null);
    const [urlIsEdited, setUrlIsEdited] = useState(false);
    const subHeader = useSubheader();

    // Selection related
    const [enableSmartDongleSelection, setEnableSmartDongleSelection] = useState(false);
    const [showButtonsRelatedToSelection, setShowButtonsRelatedToSelection] = useState(false);
    const [showAccessManagementModal, setShowAccessManagementModal] = useState(false);
    const [showEventsHistoryModal, setShowEventsHistoryModal] = useState(false);
    const [numberOfSmartDongles, setNumberOfSmartDongles] = useState(0);
    const [smartDongles, setSmartDongles] = useState([]);
    const [disableActionButton, setDisableActionButton] = useState(false);

    const deviceServiceName = getServiceName('deviceService');
    const smartDongleFeature = getFeatureName('smartDongleFeature');
    const eventHistoryFeature = getFeatureName('eventHistoryFeature');
    const listActionName = getActionName('listAction');
    const createActionName = getActionName('createAction');
    const updateActionName = getActionName('updateAction');

    const generalRequiredMessage = language === 'EN'? 'Please provide above information!':'দয়া করে উপরের তথ্যটি দিন!'
    let fileReader

    const smartDongleListColumns = [
        {
            field: 'serial',
            title: language === 'EN'? 'Serial Number':'ক্রমিক নাম্বার',
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            }
            // disableClick: true,
        },
        {
            field: 'macAddress',
            title: language === 'EN'? 'Mac address':'ম্যাক এড্রেস',
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            }
            // disableClick: true,
        },
        {
            field: 'hardware',
            title: language === 'EN'? 'Hardware':'হার্ডওয়্যার',
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            }
            // disableClick: true,
        },
        {
            field: 'firmware',
            title: 'Current F.W.',
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            }
            // disableClick: true,
        },
        {
            field: 'next_updatable_firmware',
            title: 'Allowed F.W.',
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            }
        },
        {
            field: 'organization_names',
            title: language === 'EN'? 'Access mgmt.':'এক্সেস ম্যানেজমেন্ট',
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            // disableClick: true,
            render: (rowData) => {
                let organizationNames = ""
                if (rowData.organization_names && rowData.organization_names.length > 0) {
                    let returnedOrganizationNames = rowData.organization_names
                    let numberOfNames = returnedOrganizationNames.length
                    for (let i=0; i<numberOfNames; i++) {
                        if (i !== numberOfNames - 1) {
                            organizationNames += returnedOrganizationNames[i] + ", "
                        } else {
                            organizationNames += returnedOrganizationNames[i]
                        }
                    }
                } else {
                    organizationNames = "-"
                }
                return <>{organizationNames}</>
            }
        },
        {
            field: 'latest_event_details',
            title: language === 'EN'? 'Latest Event':'সর্বশেষ ঘটনা',
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            // disableClick: true,
            render: (rowData) => {
                if (rowData.latest_event_details) {
                    return (
                        <>
                            <small><strong>Happened on {rowData.latestEventDate} at {rowData.latestEventTime}</strong></small>
                            <p>{rowData.latestEventDescription}</p>
                        </>
                    )
                }
            }
        }
    ]

    const {
        GET_SMART_DONGLE_LIST,
        UPDATE_BULK_SMART_DONGLE_INFO,
        GENERATE_SERIAL_NUMBERS_FOR_SMART_DONGLES
    } = props;
    const classes = useStylesIconButton();

    const invalidSerial = language === 'EN'? 'Invalid serial number!':'ভুল সিরিয়াল নাম্বার!'

    const resetFilter = () => {
        resetFilterValues()
        clearErrors()
        setFilterApplied(false)
        if (filterApplied) {
            // Resetting the smart dongle list
            setPage(1)
            props.history.push({
                search: 'page=1'
            })
        }
    }

    const resetFilterValues = () => {
        setValue('serial_number', '')
        setValue('mac_address', '')
    }

    const filterTheList = (data) => {
        setPage(1)
        let searchText = 'page=1'
        if (data.serial_number) {
            searchText += '&serial_number=' + data.serial_number
        }
        if (data.mac_address) {
            searchText += '&mac_address=' + data.mac_address
        }
        props.history.push({
            search: searchText
        })
    }

    const filterToggle = () => {
        setShowFilter(!showFilter)
    }

    useEffect(() => {
        setLanguage(props.language)
        setAuthorization(props.authorization)
        if (!props.history.location.search) {
            props.history.push({
                search: 'page=1'
            })
        }

        if (checkAuthorization(props.authorization, deviceServiceName, smartDongleFeature, [createActionName]) ||
            checkAuthorization(props.authorization, deviceServiceName, smartDongleFeature, [updateActionName])) {
            subheader.setActionButtons(
                <div className="d-flex align-item-center justify-content-end">
                    <Button variant="warning" size={'sm'} type="button" style={{
                        backgroundColor: '#F18D00 !important',
                    }} onClick={showSerialNumberGeneration} classes={classes} >
                        <i className="fa fa-random"/>{props.language === 'EN'? 'Generate serial numbers': 'সিরিয়াল নাম্বার তৈরি'}
                    </Button>
                    {checkAuthorization(props.authorization, deviceServiceName, smartDongleFeature, [updateActionName])? <>
                        <Button variant="warning" size={'sm'} type="button" style={{
                            backgroundColor: '#F18D00 !important',
                        }} onClick={showUploadCsvModal} classes={classes} className={'ml-1'}>
                            <i className="fa fa-edit"/>{props.language === 'EN'? 'Update multiple smart dongles': 'একাধিক স্মার্ট ডঙ্গলের তথ্য সম্পাদনা'}
                        </Button>
                    </>:null}
                    <Button variant="warning" size={'sm'} type="button" style={{
                        backgroundColor: '#F18D00 !important',
                    }} onClick={goToAddSmartDongle} classes={classes} className={'ml-1'}>
                        <i className="fa fa-plus"/>{props.language === 'EN'? 'Add smart dongle': 'স্মার্ট ডঙ্গল নিবন্ধন'}
                    </Button>
                </div>
            )
        } else {
            // This else statement seems not needed as we are running return() below, which is setting subHeader to null
            // Before mounting this component - Noor Reza, 18th Jan, 2023
            subheader.setActionButtons(null)
        }
        subHeader.setBreadCrumbComponent(null)

        if (checkAuthorization(props.authorization, deviceServiceName, eventHistoryFeature, [createActionName]) ||
            checkAuthorization(props.authorization, deviceServiceName, eventHistoryFeature, [listActionName]) ||
            (checkAuthorization(props.authorization, deviceServiceName, smartDongleFeature, [updateActionName]) &&
                props.is_solshare_user)) {
            setEnableSmartDongleSelection(true)
        }

        return ()=>{
            subHeader.setActionButtons(null)
            subHeader.setBreadCrumbComponent(null)
            props.RETURN_TO_INITIAL_STATE();
        }
    }, []);

    // ----- Begin: Section related to shareable link/handling query params ---- //
    const validationKeyArray = ['page', 'serial_number', 'mac_address']

    useEffect(() => {
        let temp = null;
        try {
            temp = parseParams(props.history?.location?.search);
            if (Object.keys(temp)?.length > 0) {
                /* Set the projected filter lists. Requires validation */
                setQueryParams(temp);
            }
        } catch (e) {
            console.debug(e);
        }
    },[props.history.location.search])

    useEffect(() => {
        let keys = null;
        const validParameters = {};
        /* Only these keys in the projected params are valid in the projected params */

        /* Getting the keys in the projected params */
        try {
            keys = Object.keys(queryParams);
        } catch (e) {
            console.log(e)
            return;
        }
        /* Checking for if keys value is an array and has at least one value */
        if (Array.isArray(keys) && keys.length > 0) {
            /* Looping through each of the value in the keys. (Essentially This loop will run for every key in the projected params object) */
            keys.forEach((item) => {
                /* We are checking if the key is valid by checking against validationKeyArray. if it matches we include it in the validParams object */
                const itemExists = validationKeyArray.includes(item)
                if (itemExists) {
                    Object.assign(validParameters, {[`${item}`]: queryParams[item]})
                }
            })
        }
        setInitialfilterParam(Object.keys(validParameters?.length > 0) ? {...validParameters} : null)
        setFilterParamForApiCall(Object.keys(validParameters?.length > 0) ? {...validParameters} : null)
    }, [queryParams])

    useEffect(() => {
        if (initialfilterParam) {
            setUrlIsEdited(true)
            if (initialfilterParam.page) {
                let page = initialfilterParam.page
                if (/^(0|[1-9][0-9]*)$/i.test(page)) {
                    setPage(parseInt(page))
                } else {
                    toast.error("Please provide only number as page count! Collecting data for first page..")
                }
            }
            if (initialfilterParam.serial_number) {
                let serialNumberFromUrl = initialfilterParam.serial_number
                setValue('serial_number', serialNumberFromUrl)
            }
            if (initialfilterParam.mac_address) {
                let macAddressFromUrl = initialfilterParam.mac_address
                setValue('mac_address', macAddressFromUrl)
            }
            setInitialfilterParam(null)
        }
    }, [initialfilterParam])

    useEffect(() => {
        if (urlIsEdited && Object.keys(errors).length === 0) {
            // Page will always be there
            let filter = {page: page}
            let filterApplied = false
            if (filterParamForApiCall.serial_number) {
                filter['serial_number'] = filterParamForApiCall.serial_number
                filterApplied = true
            }
            if (filterParamForApiCall.mac_address) {
                filter['mac_address'] = filterParamForApiCall.mac_address
                filterApplied = true
            }
            setFilterApplied(filterApplied)
            GET_SMART_DONGLE_LIST({...filter})
            setUrlIsEdited(false)
        }
    }, [urlIsEdited]);
    // ----- End: Section related to shareable link/handling query params ---- //

    useEffect(() => {
        setShowButtonsRelatedToSelection(false)
        if (props.smartDongleList) {
            const smartDongleList = props.smartDongleList.results;
            if (smartDongleList && smartDongleList.length > -1) {
                setSmartDongleList(smartDongleList.map((smartDongle, index) => {
                    return {
                        ...smartDongle,
                        serial: smartDongle.serial_number,
                        macAddress: smartDongle.mac_address,
                        hardware: smartDongle.hardware_version,
                        firmware: smartDongle.firmware_version,
                        status: smartDongle.assignment_status,
                        // latestEventCategory: smartDongle.latest_event_details? smartDongle.latest_event_details.event_category_info.name:'-',
                        latestEventDate: smartDongle.latest_event_details? moment(smartDongle.latest_event_details.created_at).format("MMM DD, YYYY"):'-',
                        // Added extra '+00:00' to show correct time after formation
                        // Event History API returns '+00:00' and showing correct time hence this has been done
                        latestEventTime: smartDongle.latest_event_details? moment(smartDongle.latest_event_details.created_at + '+00:00').format("hh:mm A"):'-',
                        latestEventDescription: smartDongle.latest_event_details? parse(smartDongle.latest_event_details.event_description):'-'
                    }
                }))
            }
        }
    }, [props.smartDongleList]);

    useEffect(() => {
        if (props.successMessageForBulkSmartDongleUpdate !== undefined) {
            if (language === 'EN') {
                showNotifications('success', props.successMessageForBulkSmartDongleUpdate)
            } else {
                toast.success("শ্মার্ট ডঙ্গলসমূহের তথ্য সম্পাদনা সম্পন্ন হয়েছে!")
            }
        } else if (props.errorMessageForBulkSmartDongleUpdate !== undefined) {
            showNotifications('error', props.errorMessageForBulkSmartDongleUpdate)
        }
    }, [props.successMessageForBulkSmartDongleUpdate, props.errorMessageForBulkSmartDongleUpdate]);

    useEffect(() => {
        if (props.successMessageForSerialNumberGeneration !== undefined) {
            if (language === 'EN') {
                showNotifications('success', props.successMessageForSerialNumberGeneration)
            } else {
                toast.success("সফলভাবে স্মার্টডঙ্গল তৈরি হয়েছে!")
            }
        } else if (props.errorMessageForSerialNumberGeneration !== undefined) {
            showNotifications('error', props.errorMessageForSerialNumberGeneration)
        }
    }, [props.successMessageForSerialNumberGeneration, props.errorMessageForSerialNumberGeneration]);

    useEffect(() => {
        if (props.bulkSmartDongleUpdated === requestCycle.success) {
            setShowUploadModal(false)
            let searchText = 'page=' + page
            if (filterApplied) {
                if (filterParamForApiCall.serial_number) {
                    searchText += '&serial_number=' + filterParamForApiCall.serial_number
                }
                if (filterParamForApiCall.dongle_serial_number) {
                    searchText += '&mac_address=' + filterParamForApiCall.mac_address
                }
            }
            props.history.push({
                search: searchText
            })
        }
    },[props.bulkSmartDongleUpdated])

    useEffect(() => {
        if (props.serialNumberGenerated === requestCycle.success) {
            let generatedSerialNumbers = props.successMessageForSerialNumberGeneration['serial_number_list']
            let formattedSerialsForCsv = [{'Serial Number': '', 'Mac address': '',
                'Hardware version': '', 'Firmware version': ''}]
            if (generatedSerialNumbers.length > 0) {
                formattedSerialsForCsv = generatedSerialNumbers.map((serial) => {
                    return {
                        'Serial Number': serial,
                        'Mac address': '',
                        'Hardware version': '',
                        'Firmware version': ''
                    }
                })
            }
            let currentDate = moment().format("DD_MMM_YYYY")
            const options = {
                fieldSeparator: ',',
                quoteStrings: '"',
                decimalSeparator: '.',
                showLabels: true,
                showTitle: false,
                title: `` ,
                filename: `smart_dongles_` + currentDate,
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true
            }
            const csvExporter = new ExportToCsv(options);
            csvExporter.generateCsv(formattedSerialsForCsv)

            setShowSerialNumberGenerationModal(false)

            setTimeout(() => {
                let filter = {page: page}
                if (filterParamForApiCall.serial_number) {
                    filter['serial_number'] = filterParamForApiCall.serial_number
                }
                if (filterParamForApiCall.mac_address) {
                    filter['mac_address'] = filterParamForApiCall.mac_address
                }
                GET_SMART_DONGLE_LIST({...filter})
            }, 1000);
        }
    },[props.serialNumberGenerated])

    useEffect(()=>{
        setCsvUploadInProgress(props.bulkSmartDongleInfoSubmitting)
    },[props.bulkSmartDongleInfoSubmitting])

    const onChangePage = (event, newPage) => {
        setPage(newPage)
        let searchText = 'page=' + newPage
        if (filterApplied) {
            if (filterParamForApiCall.serial_number) {
                searchText += '&serial_number=' + filterParamForApiCall.serial_number
            }
            if (filterParamForApiCall.dongle_serial_number) {
                searchText += '&mac_address=' + filterParamForApiCall.mac_address
            }
        }
        props.history.push({
            search: searchText
        })
    }

    const showSerialNumberGeneration = () => {
        setShowSerialNumberGenerationModal(true)
    }

    const showUploadCsvModal = () => {
        setShowUploadModal(true)
    }

    const goToAddSmartDongle = () => {
        props.history.push({
            pathname: '/dongles/create',
            purpose: 'create'
        })
    }

    const goToUpdateSmartDongle = (rowData) => {
        props.history.push({
            pathname: '/dongles/edit',
            purpose: 'edit',
            dongleData: rowData
        })
    }

    const closeGenerateSerialNumberModal = () => {
        setShowSerialNumberGenerationModal(false)
    }
    
    const closeUploadCsvModal = () => {
        setShowUploadModal(false)
    }

    const fileUploadEvent = (e) => {
        let file = e.target.files[0]
        setShowFileMandatoryMessage(false)
        if (file) {
            if (file.type === "text/csv") {
                setFileIsInvalid(false)
                fileReader = new FileReader()
                fileReader.onloadend = processUploadedContent
                fileReader.readAsText(file)
                setFileUploaded(true)
            } else {
                setFileIsInvalid(true)
            }
        } else {
            setFileIsInvalid(false)
            setFileUploaded(false)
        }
    }

    const processUploadedContent = () => {
        let fileContent = fileReader.result
        let dataArray = fileContent.split(/\n/).slice(1)
        let uploadedData = []
        let notAllContentProvided = false
        for (let i = 0; i < dataArray.length - 1; i++) {
            let content = dataArray[i].split(',')
            if (content[0] === undefined || content[1] === undefined || content[2] === undefined || content[3] === undefined) {
                notAllContentProvided = true
                break
            } else {
                uploadedData.push({
                    'serial_number': content[0],
                    'mac_address': content[1],
                    'hardware_version': content[2],
                    'firmware_version': content[3]
                })
            }
        }
        if (notAllContentProvided) {
            setFileIsInvalid(true)
        } else {
            setFileIsInvalid(false)
            setUploadedContent(uploadedData)
        }
    }

    const bulkSmartDongleUpdate = () => {
        if (fileUploaded) {
            if (!fileIsInvalid) {
                UPDATE_BULK_SMART_DONGLE_INFO(uploadedContent)
            }
        } else {
            setShowFileMandatoryMessage(true)
        }
    }

    const generateSerialNumbers = (data) => {
        GENERATE_SERIAL_NUMBERS_FOR_SMART_DONGLES({'no_of_dongle': data.number_of_smart_dongles})
    }

    const downloadCsvTemplate = () => {
        let currentDate = moment().format("DD_MMM_YYYY")
        const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: false,
            title: `` ,
            filename: `smart_dongles_` + currentDate,
            useTextFile: false,
            useBom: true,
            headers: ['Serial number', 'Mac address', 'Hardware version', 'Firmware version'],
        }
        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv([{}])
    }

    const renderSerialNumberGenerationModal = () => {
        return <>
            <GenericModal
                showModalHeader={true}
                footer={false}
                hideModal={closeGenerateSerialNumberModal}
                centered={true}
                modal={showSerialNumberGenerationModal}
                title={<>{language === 'EN'? 'Generate serial numbers for Smart Dongles':'স্মার্ট ডঙ্গলের জন্য সিরিয়াল নাম্বার তৈরি'}</>}>
                <Form onSubmit={handleSubmit(generateSerialNumbers)}>
                    <div className={'row g-3'}>
                        <div className={'col-md-12'}>
                            <Form.Group>
                                <div>
                                    <Form.Label>Number of Smart Dongles<span className="required text-danger">*</span></Form.Label>
                                </div>
                                <SolInput
                                    name={'number_of_smart_dongles'}
                                    type={"number"}
                                    min={1}
                                    max={10}
                                    step={1}
                                    placeholder={"Number of dongles for which serial numbers will be generated..."}
                                    autoComplete={"off"}
                                    ref={register({
                                        required: generalRequiredMessage
                                    })}
                                />
                                {errors.number_of_smart_dongles && <div className="text-danger">
                                    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.number_of_smart_dongles.message}</div>}
                            </Form.Group>
                        </div>
                    </div>
                    <hr/>
                    <div className={'row mt-3'}>
                        <div className={"col-md-12"}>
                            <Stack
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="flex-start"
                                spacing={2}
                            >
                                <Button variant={"outline-dark"} type={"button"} size={'md'}
                                        disabled={props.numberGenerationRelatedInfoSubmitting}
                                        onClick={closeGenerateSerialNumberModal}>
                                    Cancel
                                </Button>
                                <Button variant="warning" size={'md'} type="submit"
                                        disabled={props.numberGenerationRelatedInfoSubmitting}
                                        style={{backgroundColor: '#F18D00'}}>
                                    {props.numberGenerationRelatedInfoSubmitting? <><Spinner animation={'border'} size={'sm'}
                                                                                             variant={'light'}/>&nbsp;</>:''}Generate
                                </Button>
                            </Stack>
                        </div>
                    </div>
                </Form>
            </GenericModal>
        </>
    }

    const renderCsvUploadModal = () => {
        return <>
            <GenericModal
                showModalHeader={true}
                footer={true}
                footerButtonSize={'sm'}
                footerCloseButtonText={language === 'EN'? 'Cancel':'বাতিল করুন'}
                footerActionButtonText={language === 'EN'? 'Confirm':'নিশ্চিত করুন'}
                hideModal={closeUploadCsvModal}
                takeAction={bulkSmartDongleUpdate}
                centered={true}
                modal={showUploadModal}
                disableButton={csvUploadInProgress}
                title={<>{language === 'EN'? 'Update Smart Dongle information by uploading csv':'সিএসভি ফাইল আপলোডের মাধ্যমে স্মার্ট ডঙ্গল তথ্য সম্পাদনা'}</>}>
                <p>A blank CSV template can be downloaded from <a href={"#"} style={{'color': '#F18D00'}} onClick={() => {downloadCsvTemplate()}}>here</a>.</p>
                <label className="form-label" htmlFor="customFile">{language === 'EN'? 'Upload':'আপলোড'}</label>
                <input type="file" className="form-control" id="customFile" onChange={fileUploadEvent}/>
                {fileIsInvalid? <span className="text-danger">Invalid file!</span>:<></>}
                {showFileMandatoryMessage? <span className="text-danger">Please upload a file!</span>:<></>}
            </GenericModal>
        </>
    }


    // --------------- Access Management ------------------- //

    const onSelection = (data) => {
        let numberSelectedSmartDongles = data.length
        if (numberSelectedSmartDongles > 0) {
            setDisableActionButton(true)
            setNumberOfSmartDongles(numberSelectedSmartDongles)
            setSmartDongles(data)
            setShowButtonsRelatedToSelection(true)
        } else {
            setDisableActionButton(false)
            setShowButtonsRelatedToSelection(false)
        }
    }

    const handleAccessManagementButtonClick = () => {
        setShowAccessManagementModal(true)
    }

    const hideAccessManagementModal = () => {
        setShowAccessManagementModal(false)
    }

    const renderAccessManagementModal = () => {
        return <>
            <GenericModal
                size={'lg'}
                showModalHeader={true}
                footer={false}
                hideModal={hideAccessManagementModal}
                centered={false}
                modal={showAccessManagementModal}
                title={<h1>Access Management</h1>}>
                <AccessManagementForSmartDongles
                    numberOfDongles={numberOfSmartDongles}
                    data={smartDongles}></AccessManagementForSmartDongles>
            </GenericModal>
        </>
    }

    useEffect(() => {
        if (props.organizationalAccessIsUpdated === requestCycle.success) {
            setShowButtonsRelatedToSelection(false)
            setDisableActionButton(false)
            hideAccessManagementModal()
            let filter = {page: page}
            if (filterParamForApiCall.serial_number) {
                filter['serial_number'] = filterParamForApiCall.serial_number
            }
            if (filterParamForApiCall.mac_address) {
                filter['mac_address'] = filterParamForApiCall.mac_address
            }
            GET_SMART_DONGLE_LIST({...filter})
        }
    }, [props.organizationalAccessIsUpdated])

    useEffect(() => {
        if (props.successMessageForOrganizationalAccessUpdateProcess !== undefined) {
            language === 'EN' ? showNotifications('success', props.successMessageForOrganizationalAccessUpdateProcess) : toast.success("সফলভাবে সম্পাদনা শেষ হয়েছে!")
        } else if (props.errorMessageForOrganizationalAccessUpdateProcess !== undefined) {
            showNotifications('error', props.errorMessageForOrganizationalAccessUpdateProcess)
        }
    }, [props.successMessageForOrganizationalAccessUpdateProcess, props.errorMessageForOrganizationalAccessUpdateProcess])

    // --------------- Event History ------------------- //
    const handleEventHistoryButtonClick = () => {
        setShowEventsHistoryModal(true)
    }

    const hideEventHistoryModal = () => {
        setShowEventsHistoryModal(false)
    }

    const renderEventHistoryModal = () => {
        return <>
            <GenericModal
                size={'lg'}
                showModalHeader={true}
                footer={false}
                hideModal={hideEventHistoryModal}
                centered={false}
                modal={showEventsHistoryModal}
                title={<h1>{numberOfSmartDongles === 1? 'Events History of SOLdongle #' + smartDongles[0].serial_number:'Events History'}</h1>}>
                <EventsHistory feature={'smartDongle'} numberOfThings={numberOfSmartDongles} data={smartDongles}></EventsHistory>
            </GenericModal>
        </>
    }

    useEffect(() => {
        if (props.eventSaved === requestCycle.success) {
            if (numberOfSmartDongles > 1) {
                setSmartDongles([])
                setNumberOfSmartDongles(0)
                setShowButtonsRelatedToSelection(false)
                setDisableActionButton(false)
                hideEventHistoryModal()
                let filter = {page: page}
                if (filterParamForApiCall.serial_number) {
                    filter['serial_number'] = filterParamForApiCall.serial_number
                }
                if (filterParamForApiCall.mac_address) {
                    filter['mac_address'] = filterParamForApiCall.mac_address
                }
                GET_SMART_DONGLE_LIST({...filter})
            }
        }
    }, [props.eventSaved])

    useEffect(() => {
        if (props.successMessageForEventSave !== undefined) {
            language === 'EN' ? showNotifications('success', props.successMessageForEventSave) : toast.success("সফলভাবে ঘটনা সংরক্ষিত হয়েছে!")
        } else if (props.errorMessageForEventSave !== undefined) {
            showNotifications('error', props.errorMessageForEventSave)
        }
    }, [props.successMessageForEventSave, props.errorMessageForEventSave])

    return (
        <>
            <ContentWrapper showCardHeader={false} showBackButton={false} isLoading={props.isLoading}
                            serverError={false} permissionDenied={false} pageTitle={"Smart Dongles"}
                            statusCode={props.statusCode}>
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <Toolbar>
                            <Toolbar.Title>
                                <h1><b>{language === 'EN'? 'Smart dongles':'স্মার্ট ডঙ্গলের তালিকা'}</b></h1>
                            </Toolbar.Title>
                            <Toolbar.ToolbarContainer>
                                <Toolbar.ToolbarContainer.Items>
                                    <IconButton onClick={filterToggle} classes={classes}>
                                        <FilterListIcon color="disabled" fontSize="large"/>
                                    </IconButton>
                                </Toolbar.ToolbarContainer.Items>
                            </Toolbar.ToolbarContainer>
                        </Toolbar>
                    </div>
                </div>
                <hr/>
                {showFilter?
                    <>
                        <div className={'row'}>
                            <div className={'col-md-12'}>
                                <Form onSubmit={handleSubmit(filterTheList)}>
                                    <div className={'row g-3'}>
                                        <div className={'col-md-4'}>
                                            <Form.Group>
                                                <div>
                                                    <Form.Label>{language === 'EN'? 'Serial Number':'সিরিয়াল নাম্বার'}</Form.Label>
                                                </div>
                                                <SolInput
                                                    type={"text"}
                                                    name={"serial_number"}
                                                    placeholder={language === "EN"? "Type Serial Number...":"সিরিয়াল নাম্বার লিখুন..."}
                                                    autoComplete={"off"}
                                                    ref={register({
                                                        validate: {
                                                            isNumber: value => (/^(0|[1-9][0-9]*)$/i.test(value) || !value) || invalidSerial
                                                        }
                                                    })}
                                                />
                                                {errors.serial_number && <div className="text-danger">
                                                    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.serial_number.message}</div>}
                                            </Form.Group>
                                        </div>
                                        <div className={'col-md-4'}>
                                            <Form.Group>
                                                <div>
                                                    <Form.Label>{language === 'EN'? 'MAC Address':'ম্যাক এড্রেস'}</Form.Label>
                                                </div>
                                                <SolInput
                                                    type={"text"}
                                                    name={"mac_address"}
                                                    placeholder={language === "EN"? "Type MAC Address...":"ম্যাক এড্রেস লিখুন..."}
                                                    autoComplete={"off"}
                                                    ref={register()}
                                                />
                                                {errors.mac_address && <div className="text-danger">
                                                    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.mac_address.message}</div>}
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className={'row'}>
                                        <div className={"col-md-12"}>
                                            <Button variant="warning" size={'sm'} type="submit"
                                                    disabled={props.listLoading}>
                                                {language === 'EN'? 'Apply filter':'ফিল্টার করুন'}
                                            </Button>
                                            <Button variant="outline-dark" size={'sm'} disabled={props.listLoading}
                                                    onClick={()=> {resetFilter()}} className={'ml-3'}>
                                                {language === 'EN'? 'Reset filter':'ফিল্টার করার তথ্য পরিষ্কার করুন'}
                                            </Button>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                        <hr/></>:null}
                {showButtonsRelatedToSelection? <>
                    <div className={'row'}>
                        <div className={"col-md-12"}>
                            <Stack direction={'row'} justifyContent="flex-start" spacing={1}>
                                {(checkAuthorization(props.authorization, deviceServiceName, eventHistoryFeature, [createActionName]) ||
                                    checkAuthorization(props.authorization, deviceServiceName, eventHistoryFeature, [listActionName]))?
                                    <>
                                        <Button variant="warning" size={'sm'} type="button"
                                                onClick={handleEventHistoryButtonClick}>
                                            Events History
                                        </Button>
                                    </>:null}
                                {(checkAuthorization(props.authorization, deviceServiceName, smartDongleFeature, [updateActionName]) &&
                                    props.is_solshare_user)?
                                    <>
                                        <Button variant="warning" size={'sm'} type="button"
                                                onClick={handleAccessManagementButtonClick}>
                                            Access Management
                                        </Button>
                                    </>:null}
                            </Stack>
                        </div>
                    </div>
                </>:null}
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <DataTableContainer>
                            {!props.listLoading? <DataTable
                                language={language}
                                noDataAvailableMessageInBangla={'কোন ডঙ্গল নেই'}
                                columns={smartDongleListColumns}
                                data={smartDongleList}
                                showToolbar={false}
                                // Selection related
                                selection={enableSmartDongleSelection}
                                showSelectAllCheckbox={true}
                                onSelectionChange={onSelection}
                                // Pagination related
                                asyncPagination={true}
                                count={props.smartDongleList?.count}
                                itemsPerPage={props.smartDongleList?.page_size}
                                onChangePage={onChangePage}
                                page={page}
                                // Action button related
                                actionColumnIndex={-1}
                                actions={checkAuthorization(authorization, deviceServiceName, smartDongleFeature, [updateActionName])? [{}]:[]}
                                actionButtonVariant={['warning']}
                                actionButtonSize={'sm'}
                                actionButtonClickEvent={[goToUpdateSmartDongle]}
                                actionButtonText={language === 'EN'? ['Update']:['ডঙ্গল সম্পাদনা']}
                                actionButtonDisable={disableActionButton}
                            /> : <LoadingSpinner loadingSubText={language === 'EN'? 'Generating smart dongle list ..': 'ডঙ্গলের তালিকা প্রস্তুত হচ্ছে'} language={language}/>}
                        </DataTableContainer>
                    </div>
                </div>

                {renderCsvUploadModal()}
                {renderSerialNumberGenerationModal()}
                {renderAccessManagementModal()}
                {renderEventHistoryModal()}

            </ContentWrapper>

            <style jsx>{`
              .filter-animation {
                animation-name: breath-in;
                animation-duration: 40ms;
              }

              @keyframes breath-in {
                0% {
                  height: 0;
                  opacity: 0;
                }
                10% {
                  height: 10px;
                  opacity: 0.10;
                }
                20% {
                  height: 20px;
                  opacity: 0.20;
                }
                30% {
                  height: 30px;
                  opacity: 0.30;
                }
                40% {
                  height: 40px;
                  opacity: 0.40;
                }
                50% {
                  height: 50px;
                  opacity: 0.50;
                }
                60% {
                  height: 60px;
                  opacity: 0.60;
                }
                70% {
                  height: 70px;
                  opacity: 0.70;
                }
                80% {
                  height: 80px;
                  opacity: 0.80;
                }
                90% {
                  height: 90px;
                  opacity: 0.90;
                }
                100% {
                  height: auto;
                  opacity: 1.00;
                }
              }

              //@keyframes breath-out {
              //    0% { height: auto;  opacity: 1.00; }
              //    10% { height: 90px;  opacity: 0.90; }
              //    20% { height: 80px;  opacity: 0.80; }
              //    30% { height: 70px;  opacity: 0.70; }
              //    40% { height: 60px;  opacity: 0.60; }
              //    50% { height: 50px;  opacity: 0.50; }
              //    60% { height: 40px;  opacity: 0.40; }
              //    70% { height: 30px;  opacity: 0.30; }
              //    80% { height: 20px;  opacity: 0.20; }
              //    90% { height: 10px;  opacity: 0.10; }
              //    100% { height: 0; opacity: 0;  }  
              //}


            `}</style>
        </>
    );
};

SmartDongleList.propTypes = {};

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        is_solshare_user: state.auth.is_solshare_user,
        isLoading: state.smartDongleReducer.isLoading,
        tableLoading: state.smartDongleReducer.tableLoading,
        statusCode: state.smartDongleReducer.statusCode,
        smartDongleList: state.smartDongleReducer.smartDongleList,
        filterTriggeredLoading: state.smartDongleReducer.filterTriggeredLoading,
        bulkSmartDongleInfoSubmitting: state.smartDongleReducer.bulkSmartDongleInfoSubmitting,
        bulkSmartDongleUpdated: state.smartDongleReducer.bulkSmartDongleUpdated,
        successMessageForBulkSmartDongleUpdate: state.smartDongleReducer.successMessageForBulkSmartDongleUpdate,
        errorMessageForBulkSmartDongleUpdate: state.smartDongleReducer.errorMessageForBulkSmartDongleUpdate,
        serialNumberGenerated: state.smartDongleReducer.serialNumberGenerated,
        successMessageForSerialNumberGeneration: state.smartDongleReducer.successMessageForSerialNumberGeneration,
        errorMessageForSerialNumberGeneration: state.smartDongleReducer.errorMessageForSerialNumberGeneration,
        numberGenerationRelatedInfoSubmitting: state.smartDongleReducer.numberGenerationRelatedInfoSubmitting,
        listLoading: state.smartDongleReducer.listLoading,
        iconLoading: state.smartDongleReducer.iconLoading,
        organizationalAccessIsUpdated: state.smartDongleReducer.organizationalAccessIsUpdated,
        successMessageForOrganizationalAccessUpdateProcess: state.smartDongleReducer.successMessageForOrganizationalAccessUpdateProcess,
        errorMessageForOrganizationalAccessUpdateProcess: state.smartDongleReducer.errorMessageForOrganizationalAccessUpdateProcess,
        eventSaved: state.commonReducer.eventSaved,
        successMessageForEventSave: state.commonReducer.successMessageForEventSave,
        errorMessageForEventSave: state.commonReducer.errorMessageForEventSave
    }
}

export default connect(mapStateToProps, actions)(withRoles(SmartDongleList));
