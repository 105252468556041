export const actionTypes = {

    /*Setting the isLoading flag to True.
    * isLoading flag is responsible for
    * the toggling spinner.*/
    SET_IS_LOADING_TO_TRUE: 'SET_IS_LOADING_TO_TRUE',

    COLLECT_DISTRICTS_REQUEST: 'COLLECT_DISTRICTS_REQUEST',
    COLLECT_DISTRICTS_SUCCESS: 'COLLECT_DISTRICTS_SUCCESS',
    COLLECT_DISTRICTS_FAILURE: 'COLLECT_DISTRICTS_FAILURE',

    COLLECT_UPAZILAS_FOR_ALL_REQUEST: 'COLLECT_UPAZILAS_FOR_ALL_REQUEST',
    COLLECT_UPAZILAS_FOR_ALL_SUCCESS: 'COLLECT_UPAZILAS_FOR_ALL_SUCCESS',
    COLLECT_UPAZILAS_FOR_ALL_FAILURE: 'COLLECT_UPAZILAS_FOR_ALL_FAILURE',

    COLLECT_UPAZILAS_FOR_BORROWER_REQUEST: 'COLLECT_UPAZILAS_FOR_BORROWER_REQUEST',
    COLLECT_UPAZILAS_FOR_BORROWER_SUCCESS: 'COLLECT_UPAZILAS_FOR_BORROWER_SUCCESS',
    COLLECT_UPAZILAS_FOR_BORROWER_FAILURE: 'COLLECT_UPAZILAS_FOR_BORROWER_FAILURE',

    COLLECT_UPAZILAS_FOR_GARAGE_REQUEST: 'COLLECT_UPAZILAS_FOR_GARAGE_REQUEST',
    COLLECT_UPAZILAS_FOR_GARAGE_SUCCESS: 'COLLECT_UPAZILAS_FOR_GARAGE_SUCCESS',
    COLLECT_UPAZILAS_FOR_GARAGE_FAILURE: 'COLLECT_UPAZILAS_FOR_GARAGE_FAILURE',

    COLLECT_THANAS_FOR_BORROWER_REQUEST: 'COLLECT_THANAS_FOR_BORROWER_REQUEST',
    COLLECT_THANAS_FOR_BORROWER_SUCCESS: 'COLLECT_THANAS_FOR_BORROWER_SUCCESS',
    COLLECT_THANAS_FOR_BORROWER_FAILURE: 'COLLECT_THANAS_FOR_BORROWER_FAILURE',

    COLLECT_THANAS_FOR_GARAGE_REQUEST: 'COLLECT_THANAS_FOR_GARAGE_REQUEST',
    COLLECT_THANAS_FOR_GARAGE_SUCCESS: 'COLLECT_THANAS_FOR_GARAGE_SUCCESS',
    COLLECT_THANAS_FOR_GARAGE_FAILURE: 'COLLECT_THANAS_FOR_GARAGE_FAILURE',

    COLLECT_BORROWER_LIST_FOR_FILTER_FORM_REQUEST: 'COLLECT_BORROWER_LIST_FOR_FILTER_FORM_REQUEST',
    COLLECT_BORROWER_LIST_FOR_FILTER_FORM_SUCCESS: 'COLLECT_BORROWER_LIST_FOR_FILTER_FORM_SUCCESS',
    COLLECT_BORROWER_LIST_FOR_FILTER_FORM_FAILURE: 'COLLECT_BORROWER_LIST_FOR_FILTER_FORM_FAILURE',

    COLLECT_GARAGE_LIST_FOR_TABLE_REQUEST: 'COLLECT_GARAGE_LIST_FOR_TABLE_REQUEST',
    COLLECT_GARAGE_LIST_FOR_TABLE_SUCCESS: 'COLLECT_GARAGE_LIST_FOR_TABLE_SUCCESS',
    COLLECT_GARAGE_LIST_FOR_TABLE_FAILURE: 'COLLECT_GARAGE_LIST_FOR_TABLE_FAILURE',

    COLLECT_GARAGE_LIST_FOR_EXPORT_REQUEST: 'COLLECT_GARAGE_LIST_FOR_EXPORT_REQUEST',
    COLLECT_GARAGE_LIST_FOR_EXPORT_SUCCESS: 'COLLECT_GARAGE_LIST_FOR_EXPORT_SUCCESS',
    COLLECT_GARAGE_LIST_FOR_EXPORT_FAILURE: 'COLLECT_GARAGE_LIST_FOR_EXPORT_FAILURE',

    COLLECT_GARAGE_LIST_FOR_SELECT_FIELD_REQUEST: 'COLLECT_GARAGE_LIST_FOR_SELECT_FIELD_REQUEST',
    COLLECT_GARAGE_LIST_FOR_SELECT_FIELD_SUCCESS: 'COLLECT_GARAGE_LIST_FOR_SELECT_FIELD_SUCCESS',
    COLLECT_GARAGE_LIST_FOR_SELECT_FIELD_FAILURE: 'COLLECT_GARAGE_LIST_FOR_SELECT_FIELD_FAILURE',

    COLLECT_GARAGE_DETAILS_REQUEST: 'COLLECT_GARAGE_DETAILS_REQUEST',
    COLLECT_GARAGE_DETAILS_SUCCESS: 'COLLECT_GARAGE_DETAILS_SUCCESS',
    COLLECT_GARAGE_DETAILS_FAILURE: 'COLLECT_GARAGE_DETAILS_FAILURE',

    COLLECT_PROVIDED_SMART_BATTERIES_INFO_REQUEST: 'COLLECT_PROVIDED_SMART_BATTERIES_INFO_REQUEST',
    COLLECT_PROVIDED_SMART_BATTERIES_INFO_SUCCESS: 'COLLECT_PROVIDED_SMART_BATTERIES_INFO_SUCCESS',
    COLLECT_PROVIDED_SMART_BATTERIES_INFO_FAILURE: 'COLLECT_PROVIDED_SMART_BATTERIES_INFO_FAILURE',

    UPDATE_GARAGE_ACTIVATION_STATUS_REQUEST: 'UPDATE_GARAGE_ACTIVATION_STATUS_REQUEST',
    UPDATE_GARAGE_ACTIVATION_STATUS_SUCCESS: 'UPDATE_GARAGE_ACTIVATION_STATUS_SUCCESS',
    UPDATE_GARAGE_ACTIVATION_STATUS_FAILURE: 'UPDATE_GARAGE_ACTIVATION_STATUS_FAILURE',

    COLLECT_ACTIVITIES_OF_A_GARAGE_REQUEST: 'COLLECT_ACTIVITIES_OF_A_GARAGE_REQUEST',
    COLLECT_ACTIVITIES_OF_A_GARAGE_SUCCESS: 'COLLECT_ACTIVITIES_OF_A_GARAGE_SUCCESS',
    COLLECT_ACTIVITIES_OF_A_GARAGE_FAILURE: 'COLLECT_ACTIVITIES_OF_A_GARAGE_FAILURE',

    COLLECT_ACTIVITIES_OF_A_GARAGE_FOR_EXPORT_REQUEST: 'COLLECT_ACTIVITIES_OF_A_GARAGE_FOR_EXPORT_REQUEST',
    COLLECT_ACTIVITIES_OF_A_GARAGE_FOR_EXPORT_SUCCESS: 'COLLECT_ACTIVITIES_OF_A_GARAGE_FOR_EXPORT_SUCCESS',
    COLLECT_ACTIVITIES_OF_A_GARAGE_FOR_EXPORT_FAILURE: 'COLLECT_ACTIVITIES_OF_A_GARAGE_FOR_EXPORT_FAILURE',

    COLLECT_TRANSACTIONS_OF_A_GARAGE_REQUEST: 'COLLECT_TRANSACTIONS_OF_A_GARAGE_REQUEST',
    COLLECT_TRANSACTIONS_OF_A_GARAGE_SUCCESS: 'COLLECT_TRANSACTIONS_OF_A_GARAGE_SUCCESS',
    COLLECT_TRANSACTIONS_OF_A_GARAGE_FAILURE: 'COLLECT_TRANSACTIONS_OF_A_GARAGE_FAILURE',

    COLLECT_TRANSACTIONS_OF_A_GARAGE_FOR_EXPORT_REQUEST: 'COLLECT_TRANSACTIONS_OF_A_GARAGE_FOR_EXPORT_REQUEST',
    COLLECT_TRANSACTIONS_OF_A_GARAGE_FOR_EXPORT_SUCCESS: 'COLLECT_TRANSACTIONS_OF_A_GARAGE_FOR_EXPORT_SUCCESS',
    COLLECT_TRANSACTIONS_OF_A_GARAGE_FOR_EXPORT_FAILURE: 'COLLECT_TRANSACTIONS_OF_A_GARAGE_FOR_EXPORT_FAILURE',

    UPDATE_DUE_LIMIT_REQUEST: 'UPDATE_DUE_LIMIT_REQUEST',
    UPDATE_DUE_LIMIT_SUCCESS: 'UPDATE_DUE_LIMIT_SUCCESS',
    UPDATE_DUE_LIMIT_FAILURE: 'UPDATE_DUE_LIMIT_FAILURE',

    DUE_LIMIT_CHANGE_HISTORY_REQUEST: 'DUE_LIMIT_CHANGE_HISTORY_REQUEST',
    DUE_LIMIT_CHANGE_HISTORY_SUCCESS: 'DUE_LIMIT_CHANGE_HISTORY_SUCCESS',
    DUE_LIMIT_CHANGE_HISTORY_FAILURE: 'DUE_LIMIT_CHANGE_HISTORY_FAILURE',

    MANUAL_CASH_PAYMENT_REQUEST: 'MANUAL_CASH_PAYMENT_REQUEST',
    MANUAL_CASH_PAYMENT_SUCCESS: 'MANUAL_CASH_PAYMENT_SUCCESS',
    MANUAL_CASH_PAYMENT_FAILURE: 'MANUAL_CASH_PAYMENT_FAILURE',

    REGISTER_BORROWER_REQUEST: 'REGISTER_BORROWER_REQUEST',
    REGISTER_BORROWER_SUCCESS: 'REGISTER_BORROWER_SUCCESS',
    REGISTER_BORROWER_FAILURE: 'REGISTER_BORROWER_FAILURE',

    EDIT_BORROWER_REQUEST: 'EDIT_BORROWER_REQUEST',
    EDIT_BORROWER_SUCCESS: 'EDIT_BORROWER_SUCCESS',
    EDIT_BORROWER_FAILURE: 'EDIT_BORROWER_FAILURE',

    COLLECT_INVOICES_REQUEST: 'COLLECT_INVOICES_REQUEST',
    COLLECT_INVOICES_SUCCESS: 'COLLECT_INVOICES_SUCCESS',
    COLLECT_INVOICES_FAILURE: 'COLLECT_INVOICES_FAILURE',

    COLLECT_INVOICES_PAYMENT_INFORMATION_OF_GARAGES_FOR_TABLE_REQUEST: 'COLLECT_INVOICES_PAYMENT_INFORMATION_OF_GARAGES_FOR_TABLE_REQUEST',
    COLLECT_INVOICES_PAYMENT_INFORMATION_OF_GARAGES_FOR_TABLE_SUCCESS: 'COLLECT_INVOICES_PAYMENT_INFORMATION_OF_GARAGES_FOR_TABLE_SUCCESS',
    COLLECT_INVOICES_PAYMENT_INFORMATION_OF_GARAGES_FOR_TABLE_FAILURE: 'COLLECT_INVOICES_PAYMENT_INFORMATION_OF_GARAGES_FOR_TABLE_FAILURE',

    COLLECT_INVOICES_PAYMENT_INFORMATION_OF_GARAGES_FOR_EXPORT_REQUEST: 'COLLECT_INVOICES_PAYMENT_INFORMATION_OF_GARAGES_FOR_EXPORT_REQUEST',
    COLLECT_INVOICES_PAYMENT_INFORMATION_OF_GARAGES_FOR_EXPORT_SUCCESS: 'COLLECT_INVOICES_PAYMENT_INFORMATION_OF_GARAGES_FOR_EXPORT_SUCCESS',
    COLLECT_INVOICES_PAYMENT_INFORMATION_OF_GARAGES_FOR_EXPORT_FAILURE: 'COLLECT_INVOICES_PAYMENT_INFORMATION_OF_GARAGES_FOR_EXPORT_FAILURE',

    RETURN_TO_INITIAL_STATE: 'RETURN_TO_INITIAL_STATE',
    RETURN_TO_INITIAL_STATE_FOR_TRANSACTIONS_AND_ACTIVITIES_MODAL: 'RETURN_TO_INITIAL_STATE_FOR_TRANSACTIONS_AND_ACTIVITIES_MODAL',
    RETURN_TO_INITIAL_STATE_FOR_MANUAL_CASH_PAYMENT: 'RETURN_TO_INITIAL_STATE_FOR_MANUAL_CASH_PAYMENT'
};
