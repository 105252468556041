import {actionTypes} from "./actionTypes";
import { requestCycle} from "../../utils/utilityFunctions";
import {toast} from "react-toastify";

/*Initial State for grid*/
const initialState= {
    isLoading: false,
    deviceList: undefined,
    errorMessageForDeviceListCollection: undefined,
    borrowers: undefined,
    errorMessageRegardingBorrowerListCollection: undefined,
    organisations: undefined,
    orgListCollectionError: undefined,
    branches: undefined,
    branchCreated: undefined,
    errorMessageForBranchCreation: undefined,
    events: undefined,
    errorWhileCollectingEvents: undefined,
    garageDetails: undefined,
    errorMessageForGarageDetails: undefined,
    garages: undefined,
    errorMessageForGarageListCollection: undefined,
    divisions: undefined,
    districts: undefined,
    subDistricts: undefined,
    zoneAreas: undefined,
    smartBatteries: undefined,
    errorMessageForSmartBatteryListCollection: undefined,
    vendorList: undefined,
    errorForVendorListCollection: undefined
};

const initialStateForBranchForm= {
    divisions: undefined,
    districts: undefined,
    subDistricts: undefined,
    zoneAreas: undefined
};

/*Reducer for Device's redux store*/
export const commonReducer = (state=initialState, action)=> {
    /*Check the documentation for each reducer's purpose in the actionTypes file*/
    switch (action.type) {
        case actionTypes.GET_DEVICE_LIST_REQUEST:
            return {
                ...state,
                deviceList: undefined,
                errorMessageForDeviceListCollection: undefined,
                listLoading: true
            };
        case actionTypes.GET_DEVICE_LIST_SUCCESS:
            return {
                ...state,
                listLoading: false,
                deviceList: action.payload
            };
        case actionTypes.GET_DEVICE_LIST_FAILURE:
            // toast.error("Can't load the device list, because: " + action.payload.error.msg);
            return {
                ...state,
                listLoading: false,
                errorMessageForDeviceListCollection: action.payload.error
            };

        case actionTypes.GET_FIRMWARE_LIST_REQUEST:
            return {
                ...state,
            };
        case actionTypes.GET_FIRMWARE_LIST_SUCCESS:
            return {
                ...state,
                firmwares: action.payload
            };
        case actionTypes.GET_FIRMWARE_LIST_FAILURE:
            return {
                ...state,
            };

        case actionTypes.GET_HARDWARE_LIST_REQUEST:
            return {
                ...state,
                hardwareList: undefined
            };
        case actionTypes.GET_HARDWARE_LIST_SUCCESS:
            return {
                ...state,
                hardwareList: action.payload
            };
        case actionTypes.GET_HARDWARE_LIST_FAILURE:
            // toast.error("Can't load the hardware list, because: " + action.payload.error.msg);
            return {
                ...state,
                listLoading: false
            };

        case actionTypes.GET_VENDOR_LIST_REQUEST:
            return {
                ...state,
                vendorList: undefined,
                errorForVendorListCollection: undefined,
                listLoading: true
            };
        case actionTypes.GET_VENDOR_LIST_SUCCESS:
            return {
                ...state,
                vendorList: action.payload,
                listLoading: false
            };
        case actionTypes.GET_VENDOR_LIST_FAILURE:
            return {
                ...state,
                errorForVendorListCollection: action.payload.error,
                listLoading: false
            };

        case actionTypes.GET_MODELS_LIST_REQUEST:
            return {
                ...state,
                modelsList: undefined,
                listLoading: true
            };
        case actionTypes.GET_MODELS_LIST_SUCCESS:
            return {
                ...state,
                modelsList: action.payload,
                listLoading:  false
            };
        case actionTypes.GET_MODELS_LIST_FAILURE:
            // toast.error("Can't load the hardware list, because: " + action.payload.error.msg);
            return {
                ...state,
                listLoading: false
            };

        case actionTypes.GET_GARAGE_LIST_REQUEST:
            return {
                ...state,
                collectingGarages: true,
                garages: undefined,
                errorMessageForGarageListCollection: undefined
            };
        case actionTypes.GET_GARAGE_LIST_SUCCESS:
            return {
                ...state,
                collectingGarages: false,
                garages: action.payload
            };
        case actionTypes.GET_GARAGE_LIST_FAILURE:
            return {
                ...state,
                collectingGarages: false,
                errorMessageForGarageListCollection: action.payload.error
            };

        case actionTypes.GET_BRANCH_LIST_REQUEST:
            return {
                ...state,
                collectingBranches: true,
                branches: undefined
            };
        case actionTypes.GET_BRANCH_LIST_SUCCESS:
            return {
                ...state,
                collectingBranches: false,
                branches: action.payload
            };
        case actionTypes.GET_BRANCH_LIST_FAILURE:
            toast.error("Could not fetch the branch list");
            return {
                ...state,
                branches: undefined,
                collectingBranches: false
            };

        case actionTypes.CREATE_NEW_BRANCH_REQUEST:
            return {
                ...state,
                creatingBranches: true,
                branchCreated: undefined

            };
        case actionTypes.CREATE_NEW_BRANCH_SUCCESS:
            return {
                ...state,
                creatingBranches: false,
                branchCreated: requestCycle.success
            };
        case actionTypes.CREATE_NEW_BRANCH_FAILURE:
            return {
                ...state,
                creatingBranches: false,
                branchCreated: requestCycle.failed,
                errorMessageForBranchCreation: action.payload.error
            };

        case actionTypes.GET_GARAGAES_DETAILS_REQUEST:
            return {
                ...state,
                garage: undefined,
                listLoading: true,

            };
        case actionTypes.GET_GARAGAES_DETAILS_SUCCESS:
            return {
                ...state,
                garage: action.payload,
                listLoading: false,
            };
        case actionTypes.GET_GARAGAES_DETAILS_FAILURE:
            toast.error("Could not fetch the garage details");
            return {
                ...state,
                listLoading: false,
            };

        case actionTypes.GET_ORGANISATIONS_REQUEST:
            return {
                ...state,
                orgListLoading: true,
                statusCode: undefined,
                organisations: undefined,
                orgListCollectionError: undefined
            };
        case actionTypes.GET_ORGANISATIONS_SUCCESS:
            return {
                ...state,
                orgListLoading: false,
                organisations: action.payload,
            };
        case actionTypes.GET_ORGANISATIONS_FAILURE:
            toast.error("Can't load the organization list, because: " + action.payload.error.msg);
            return {
                ...state,
                orgListLoading: false,
                statusCode: action.payload.status,
                orgListCollectionError: action.payload.error
            };

        case actionTypes.COLLECT_EVENTS_HISTORY_REQUEST:
            return {
                ...state,
                collectingEventHistory: true,
                events: undefined,
                errorWhileCollectingEvents: undefined
            };
        case actionTypes.COLLECT_EVENTS_HISTORY_SUCCESS:
            return {
                ...state,
                collectingEventHistory: false,
                events: action.payload
            };
        case actionTypes.COLLECT_EVENTS_HISTORY_FAILURE:
            return {
                ...state,
                collectingEventHistory: false,
                errorWhileCollectingEvents: action.payload.error
            };

        case actionTypes.SAVE_EVENT_REQUEST:
            return {
                ...state,
                savingEvent: true,
                eventSaved: undefined
            };
        case actionTypes.SAVE_EVENT_SUCCESS:
            return {
                ...state,
                savingEvent: false,
                eventSaved: requestCycle.success,
                successMessageForEventSave: action.payload
            };
        case actionTypes.SAVE_EVENT_FAILURE:
            return {
                ...state,
                savingEvent: false,
                eventSaved: requestCycle.failed,
                errorMessageForEventSave: action.payload.error
            };

        case actionTypes.COLLECT_BORROWERS_REQUEST:
            return {
                ...state,
                borrowers: undefined,
                errorMessageRegardingBorrowerListCollection: undefined,
                collectingBorrowers: true
            };
        case actionTypes.COLLECT_BORROWERS_SUCCESS:
            return {
                ...state,
                borrowers: action.payload,
                collectingBorrowers: false
            };
        case actionTypes.COLLECT_BORROWERS_FAILURE:
            return {
                ...state,
                errorMessageRegardingBorrowerListCollection: action.payload.error,
                collectingBorrowers: false
            };

        case actionTypes.COLLECT_GARAGE_DETAILS_REQUEST:
            return {
                ...state,
                garageDetails: undefined,
                errorMessageForGarageDetails: undefined,
                collectingGarageDetails: true
            };
        case actionTypes.COLLECT_GARAGE_DETAILS_SUCCESS:
            return {
                ...state,
                garageDetails: action.payload,
                collectingGarageDetails: false
            };
        case actionTypes.COLLECT_GARAGE_DETAILS_FAILURE:
            return {
                ...state,
                collectingGarageDetails: false,
                errorMessageForGarageDetails: action.payload.error
            };

        case actionTypes.CLEAN_UP_COMMON_STATES_RELATED_TO_GARAGE_DETAILS_ONLY:
            return {
                ...state,
                garageDetails: undefined,
                errorMessageForGarageDetails: undefined
            }

        case actionTypes.CLEAN_UP_STATES_RELATED_TO_EVENTS_HISTORY_MODAL:
            return {
                ...state,
                events: undefined,
                successMessageForEventSave: undefined,
                errorMessageForEventSave: undefined
            }

        case actionTypes.COLLECT_FINANCIERS_LIST_REQUEST:
            return {
                ...state,
                collectingFinanciers: true,
                financiers: undefined
            }
        case actionTypes.COLLECT_FINANCIERS_LIST_SUCCESS:
            return {
                ...state,
                collectingFinanciers: false,
                financiers: action.payload,
            }
        case actionTypes.COLLECT_FINANCIERS_LIST_FAILURE:
            toast.error("Failed to collect financiers! Please contact with an admin.")
            return {
                ...state,
                collectingFinanciers: false
            }

        case actionTypes.COLLECT_DIVISIONS_REQUEST:
            return {
                ...state,
                collectingDivisions: true,
                divisions: undefined
            }
        case actionTypes.COLLECT_DIVISIONS_SUCCESS:
            return {
                ...state,
                collectingDivisions: false,
                divisions: action.payload,
            }
        case actionTypes.COLLECT_DIVISIONS_FAILURE:
            toast.error("Failed to collect divisions! Please contact with an admin.")
            return {
                ...state,
                collectingDivisions: false
            }

        case actionTypes.COLLECT_DISTRICTS_REQUEST:
            return {
                ...state,
                collectingDistricts: true,
                districts: undefined
            }
        case actionTypes.COLLECT_DISTRICTS_SUCCESS:
            return {
                ...state,
                collectingDistricts: false,
                districts: action.payload,
            }
        case actionTypes.COLLECT_DISTRICTS_FAILURE:
            toast.error("Failed to collect districts! Please contact with an admin.")
            return {
                ...state,
                collectingDistricts: false
            }

        case actionTypes.COLLECT_SUB_DISTRICTS_REQUEST:
            return {
                ...state,
                collectingSubDistricts: true,
                subDistricts: undefined
            }
        case actionTypes.COLLECT_SUB_DISTRICTS_SUCCESS:
            return {
                ...state,
                collectingSubDistricts: false,
                subDistricts: action.payload,
            }
        case actionTypes.COLLECT_SUB_DISTRICTS_FAILURE:
            toast.error("Failed to collect sub-districts! Please contact with an admin.")
            return {
                ...state,
                collectingSubDistricts: false
            }

        case actionTypes.COLLECT_ZONE_AREA_FOR_BRANCH_REQUEST:
            return {
                ...state,
                zoneAreas: undefined,
                zoneAreaCollectionInProgress: true
            };
        case actionTypes.COLLECT_ZONE_AREA_FOR_BRANCH_SUCCESS:
            return {
                ...state,
                zoneAreas: action.payload,
                zoneAreaCollectionInProgress: false
            };
        case actionTypes.COLLECT_ZONE_AREA_FOR_BRANCH_FAILURE:
            toast.error("Failed to collect zone area information of the financier!")
            return {
                ...state,
                zoneAreaCollectionInProgress: false
            };

        case actionTypes.COLLECT_SMART_BATTERIES_REQUEST:
            return {
                ...state,
                smartBatteries: undefined,
                errorMessageForSmartBatteryListCollection: undefined,
                smartBatteriesListCollectionInProgress: true
            };
        case actionTypes.COLLECT_SMART_BATTERIES_SUCCESS:
            return {
                ...state,
                smartBatteries: action.payload,
                smartBatteriesListCollectionInProgress: false
            };
        case actionTypes.COLLECT_SMART_BATTERIES_FAILURE:
            return {
                ...state,
                smartBatteriesListCollectionInProgress: false,
                errorMessageForSmartBatteryListCollection: action.payload.error
            };

        case actionTypes.RETURN_TO_INITIAL_STATES_OF_BRANCH_FORM:
            return initialStateForBranchForm

        case actionTypes.RETURN_TO_INITIAL_STATES_FOR_COMMON_REDUCER:
            return initialState

        default:
            return state;
    }
};
